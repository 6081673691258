export enum AdChannelType {
  META = 'meta',
  GOOGLE = 'google',
  TWITTER_ADS = 'twitter-ads',
  LINKEDIN_ADS = 'linkedin-ads',
  REDDIT_ADS = 'reddit-ads',
  PINTEREST_ADS = 'pinterest-ads',
  TIKTOK_ADS = 'tiktok-ads',
  YOUTUBE_ADS = 'youtube-ads',
  SNAPCHAT = 'snapchat',
  BING_ADS = 'bing-ads',
}

export const adChannelTypeDisplayName = (type: AdChannelType): string => {
  switch (type) {
    case AdChannelType.META:
      return 'Meta'
    case AdChannelType.GOOGLE:
      return 'Google'
    case AdChannelType.TWITTER_ADS:
      return 'Twitter'
    case AdChannelType.LINKEDIN_ADS:
      return 'LinkedIn'
    case AdChannelType.REDDIT_ADS:
      return 'Reddit'
    case AdChannelType.PINTEREST_ADS:
      return 'Pinterest'
    case AdChannelType.TIKTOK_ADS:
      return 'TikTok'
    case AdChannelType.YOUTUBE_ADS:
      return 'YouTube'
    case AdChannelType.SNAPCHAT:
      return 'Snapchat'
    case AdChannelType.BING_ADS:
      return 'Bing'
  }
}
