<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  columns: {
    type: Number,
    required: false,
    default: 3,
  },
})
</script>
<template>
  <div class="flex w-full flex-col items-center space-y-8">
    <!--    <span class="font-sans text-2xl">{{ props.title }}</span>-->
    <div
      :class="`grid w-full grid-cols-1 gap-x-10 gap-y-9 ${props.columns === 2 ? `md:max-w-[750px] md:grid-cols-2` : `md:max-w-[1125px] md:grid-cols-3`}`"
    >
      <slot name="blocks"></slot>
    </div>
    <template v-if="$slots.comingSoon">
      <div class="flex flex-col items-center space-y-3 text-sm">
        <span>{{ t('landing.integrations.coming-soon') }}</span>
        <div class="inline-flex items-center space-x-3">
          <slot name="comingSoon"></slot>
        </div>
      </div>
    </template>
  </div>
</template>
