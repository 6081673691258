<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<template>
  <div class="container flex h-[100px] items-center space-x-12 text-base sm:justify-center">
    <router-link
      :to="{
        name: 'terms-of-service',
      }"
      class="cursor-pointer transition-all text-white/30 hover:text-white"
      >{{ t('footer.terms') }}</router-link
    >
    <router-link
      :to="{
        name: 'privacy',
      }"
      class="cursor-pointer transition-all text-white/30 hover:text-white"
      >{{ t('footer.privacy') }}</router-link
    >
    <!--    <router-link-->
    <!--      :to="{-->
    <!--        name: 'roadmap',-->
    <!--      }"-->
    <!--      -->
    <!--      class="cursor-pointer text-zinc-400 transition-all hover:text-white/20 dark:text-white/30 dark:hover:text-white"-->
    <!--      >{{ t("footer.roadmap") }}</router-link-->
    <!--    >-->
  </div>
</template>
