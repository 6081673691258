<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import budget_thresholds from '@/assets/docs/budget-thresholds.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Budget thresholds allow you to give Charter's algorithms boundaries in addition to each budget
      group's key metric. Thresholds are maximum/minimums that you can set for Charter to operate
      within.
    </p>
  </div>
  <div class="border-l-4 border-[#9f69fb] bg-purple-50 p-4 text-purple-800" role="alert">
    <p class="font-bold">Example</p>
    <p>
      If you set a maximum CPC of $2, Charter will automatically scale down budgets that exceed a
      CPC of $2.
    </p>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <p>The thresholds you can set for budget groups are:</p>
    <ul>
      <li>Return on ad spend - ROAS</li>
      <li>Cost per purchase - CPP</li>
      <li>Cost per mille - CPM</li>
      <li>Cost per click - CPC</li>
    </ul>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_thresholds" />
    </div>
    <p>
      Is there a threshold metric you’d like to use but isn’t currently an option? Drop us a message
      to let us know!
    </p>
  </div>
</template>
