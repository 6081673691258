<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import bg from '@/assets/bg.png'
import EmailForm from '@/ui/components/auth/email-form.vue'
import PageLayout from '@/ui/components/page-layout.vue'
import { AuthContext } from '@/enums/auth-context.ts'

const route = useRoute()
const router = useRouter()

const proceed = () => {
  if (route.query.to) {
    router.replace(JSON.parse(atob(route.query.to as string)))
  } else {
    router.replace({ name: 'workspaces' })
  }
}

const back = () => {
  router.back()
}

const debug_email = computed<string | undefined>(() => {
  if (import.meta.env.VITE_ENVIRONMENT === 'production') {
    return undefined
  }
  return 'contact@colinsouth.com'
})
</script>

<template>
  <div
    :style="`
      background-color: #003335;
      background-image: url(${bg});
      background-size: cover;
      background-position: top;
      background-attachment: fixed;
      background-blend-mode: soft-light;
    `"
  >
    <div class="flex min-h-screen" style="background-color: rgba(0, 0, 0, 0.82)">
      <page-layout :centered="true" :tabs="false" :has-back-button="true" @back="back">
        <email-form
          class="w-full max-w-[380px]"
          :debug-email="debug_email"
          :auth-context="AuthContext.SIGN_IN"
          @proceed="proceed"
        ></email-form
      ></page-layout>
    </div>
  </div>
</template>
