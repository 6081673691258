<script setup lang="ts">
import type { PropType } from 'vue'

import type { Budget } from '@/models/budget.ts'
import { useCurrencyStore } from '@/stores/currency.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const currencyStore = useCurrencyStore()
const workspaceStore = useWorkspaceStore()

const props = defineProps({
  budget: {
    type: Object as PropType<Budget>,
    required: true,
  },
})
</script>
<template>
  <div
    v-if="workspaceStore.workspace"
    class="flex flex-col items-end space-y-3 min-[550px]:flex-row min-[550px]:items-center min-[550px]:space-x-5 min-[550px]:space-y-0"
  >
    <div
      :class="`flex flex-col items-end min-[550px]:items-end`"
      v-if="props.budget.forecasts.daily_revenue_forecast !== undefined"
    >
      <span
        :class="`font-sans text-xs ${props.budget.forecasts.daily_revenue_forecast > props.budget.effective_daily_budget ? `text-emerald-300/70` : `text-rose-500/70`}`"
        >Daily revenue</span
      >
      <span
        :class="`font-mono text-sm ${props.budget.forecasts.daily_revenue_forecast > props.budget.effective_daily_budget ? `text-emerald-300` : `text-rose-500/90`}`"
        >{{
          currencyStore.format(
            props.budget.forecasts.daily_revenue_forecast,
            workspaceStore.workspace.currency.id,
            props.budget.currency_id,
          )
        }}</span
      >
    </div>
  </div>
</template>
