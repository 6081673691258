<script setup lang="ts">
import Amazon from '@/ui/components/icons/payment/amazon.vue'
import AmericanExpress from '@/ui/components/icons/payment/american-express.vue'
import Bitcoin from '@/ui/components/icons/payment/bitcoin.vue'
import Delta from '@/ui/components/icons/payment/delta.vue'
import DinersClub from '@/ui/components/icons/payment/diners-club.vue'
import DirectDebit from '@/ui/components/icons/payment/direct-debit.vue'
import Discover from '@/ui/components/icons/payment/discover.vue'
import Jcb from '@/ui/components/icons/payment/jcb.vue'
import Klarna from '@/ui/components/icons/payment/klarna.vue'
import Maestro from '@/ui/components/icons/payment/maestro.vue'
import Mastercard from '@/ui/components/icons/payment/mastercard.vue'
import MoneyGram from '@/ui/components/icons/payment/money-gram.vue'
import Paypal from '@/ui/components/icons/payment/paypal.vue'
import Payu from '@/ui/components/icons/payment/payu.vue'
import Rupay from '@/ui/components/icons/payment/rupay.vue'
import Skrill from '@/ui/components/icons/payment/skrill.vue'
import Solo from '@/ui/components/icons/payment/solo.vue'
import Stripe from '@/ui/components/icons/payment/stripe.vue'
import UnionPay from '@/ui/components/icons/payment/union-pay.vue'
import Visa from '@/ui/components/icons/payment/visa.vue'
import WesternUnion from '@/ui/components/icons/payment/western-union.vue'
import WorldPay from '@/ui/components/icons/payment/world-pay.vue'

const props = defineProps({
  brand: {
    type: String,
    required: true,
  },
})
</script>
<template>
  <amazon v-if="props.brand === 'amazon'" />
  <american-express v-else-if="props.brand === 'amex'" />
  <bitcoin v-else-if="props.brand === 'bitcoin'" />
  <delta v-else-if="props.brand === 'delta'" />
  <diners-club v-else-if="props.brand === 'diners'" />
  <direct-debit v-else-if="props.brand === 'direct_debit'" />
  <discover v-else-if="props.brand === 'discover'" />
  <jcb v-else-if="props.brand === 'jcb'" />
  <klarna v-else-if="props.brand === 'klarna'" />
  <maestro v-else-if="props.brand === 'maestro'" />
  <mastercard v-else-if="props.brand === 'mastercard'" />
  <money-gram v-else-if="props.brand === 'money_gram'" />
  <paypal v-else-if="props.brand === 'paypal'" />
  <payu v-else-if="props.brand === 'payu'" />
  <rupay v-else-if="props.brand === 'rupay'" />
  <skrill v-else-if="props.brand === 'skrill'" />
  <solo v-else-if="props.brand === 'solo'" />
  <stripe v-else-if="props.brand === 'stripe'" />
  <union-pay v-else-if="props.brand === 'unionpay'" />
  <visa v-else-if="props.brand === 'visa'" />
  <western-union v-else-if="props.brand === 'western_union'" />
  <world-pay v-else-if="props.brand === 'world_pay'" />
  <div v-else></div>
</template>
