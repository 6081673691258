<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import AdChannelIcon from '@/ui/components/ad-channel-icon.vue'
import IntegrationBlock from '@/ui/components/landing/integration-block.vue'
import IntegrationGroup from '@/ui/components/landing/integration-group.vue'
import { AdChannelType } from '@/enums/ad-channel-type.ts'

const { t } = useI18n()
</script>
<template>
  <section>
    <div
      id="integrations"
      class="container flex flex-col items-center space-y-12 sm:space-y-12 sm:text-center"
    >
      <span class="block w-full font-sans text-5xl font-medium leading-tight">{{
        t('landing.integrations.title')
      }}</span>
      <div class="flex w-full flex-col space-y-16">
        <integration-group :title="t('landing.integrations.ad-platforms.title')" :columns="3">
          <template #blocks>
            <integration-block
              :title="t('landing.integrations.ad-platforms.google.title')"
              :description="t('landing.integrations.ad-platforms.google.description')"
            >
              <template #icon>
                <ad-channel-icon class="h-16 w-auto" :type="AdChannelType.GOOGLE"></ad-channel-icon>
              </template>
            </integration-block>
            <integration-block
              :title="t('landing.integrations.ad-platforms.meta.title')"
              :description="t('landing.integrations.ad-platforms.meta.description')"
            >
              <template #icon>
                <ad-channel-icon class="h-16 w-auto" :type="AdChannelType.META"></ad-channel-icon>
              </template>
            </integration-block>
            <integration-block
              :title="t('landing.integrations.ad-platforms.tiktok.title')"
              :description="t('landing.integrations.ad-platforms.tiktok.description')"
              :coming-soon="true"
            >
              <template #icon>
                <ad-channel-icon
                  class="h-16 w-auto"
                  :type="AdChannelType.TIKTOK_ADS"
                ></ad-channel-icon>
              </template>
            </integration-block>
            <integration-block
              :title="t('landing.integrations.ad-platforms.bing.title')"
              :description="t('landing.integrations.ad-platforms.bing.description')"
              :coming-soon="true"
            >
              <template #icon>
                <ad-channel-icon
                  class="h-16 w-auto"
                  :type="AdChannelType.BING_ADS"
                ></ad-channel-icon>
              </template>
            </integration-block>
            <integration-block
              :title="t('landing.integrations.ad-platforms.linkedin.title')"
              :description="t('landing.integrations.ad-platforms.linkedin.description')"
              :coming-soon="true"
            >
              <template #icon>
                <ad-channel-icon
                  class="h-16 w-auto"
                  :type="AdChannelType.LINKEDIN_ADS"
                ></ad-channel-icon>
              </template>
            </integration-block>
            <integration-block
              :title="t('landing.integrations.ad-platforms.x.title')"
              :description="t('landing.integrations.ad-platforms.x.description')"
              :coming-soon="true"
            >
              <template #icon>
                <ad-channel-icon
                  class="h-14 w-auto"
                  :type="AdChannelType.TWITTER_ADS"
                ></ad-channel-icon>
              </template>
            </integration-block>
          </template>
        </integration-group>
      </div>
    </div>
  </section>
</template>
