<script setup lang="ts">
import { DropdownMenuItem } from 'radix-vue'

const openAccountDialog = () => {
  window.dispatchEvent(new CustomEvent('openAccountMenu'))
}
</script>
<template>
  <DropdownMenuItem value="Storefronts" as-child>
    <span
      class="group relative flex cursor-pointer select-none items-center rounded-sm px-5 py-2.5 leading-none outline-none transition-colors data-[disabled]:pointer-events-none bg-black/30 hover:bg-white/20"
      @click="openAccountDialog"
      >Account</span
    >
  </DropdownMenuItem>
</template>
