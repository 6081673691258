<script setup lang="ts">
import { ref } from 'vue'

const open = ref<boolean>(false)
</script>
<template>
  <div
    class="flex cursor-pointer rounded-sm bg-white/5 pb-[18px] pl-[16px] pr-[22px] pt-[16px] transition-all duration-75 hover:bg-white/8"
    @click="open = !open"
  >
    <div class="w-[30px] flex-none text-[24px] font-light text-white/30">
      <span v-if="!open">+</span>
      <span v-else class="relative left-[2px]">-</span>
    </div>
    <div class="grow">
      <div><slot name="summary"></slot></div>
      <div v-if="open"><slot name="details"></slot></div>
    </div>
  </div>
</template>
