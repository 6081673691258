<script setup lang="ts">
import { DialogContent, DialogOverlay, DialogPortal, DialogRoot } from 'radix-vue'

const open = defineModel<boolean>()
</script>
<template>
  <DialogRoot v-model:open="open">
    <DialogPortal>
      <DialogOverlay class="data-[state=open]:animate-overlayShow dialog-overlay z-[75]" />
      <DialogContent
        class="dialog-box data-[state=open]:animate-contentShow fixed left-[50%] top-[50%] z-[76] -mt-[3%] flex translate-x-[-50%] translate-y-[-50%] flex-col"
      >
        <div class="w-auto overflow-y-auto overflow-x-hidden">
          <div :class="`flex w-auto flex-col`">
            <slot></slot>
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  </DialogRoot>
</template>
