<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import budget_group from '@/assets/docs/budget-group.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Charter’s budget groups allow you to organise budgets by different objectives so Charter's
      algorithm optimises for each individual objective.
    </p>
    <p>
      To prevent overspending, each budget group has its own maximum budget for Charter to work
      within, this can be adjusted at any time.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div class="border-l-4 border-[#9f69fb] bg-purple-50 p-4 text-purple-800" role="alert">
      <p class="font-bold">Example</p>
      <p>
        You have 2 sets of budgets, one for brand awareness where you are wanting to optimise for
        Reach, and another for sales where you are wanting to optimise for Revenue. You create 2
        budget groups for each objective, set the Reach budget to $2,000/month and the Revenue
        budget to $1,000/month.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <p>
      Budget groups can include budgets from different ad platforms - this is part of Charter's
      cross-platform functionality.
    </p>
  </div>
  <div class="prose-invert flow-root">
    <div class="border-l-4 border-[#9f69fb] bg-purple-50 p-4 text-purple-800" role="alert">
      <p class="font-bold">Example</p>
      <p>
        Within your Reach budget group you have 2 Meta budgets and 1 Google budget. Charter
        identifies that the Google budget is performing better than one of the Meta budgets for your
        objective of Reach, so moves budget from the Meta budget to the Google budget.
      </p>
    </div>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-4 pr-4">
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_group" />
    </div>
  </div>
</template>
