import { Preferences } from '@capacitor/preferences'
import { watch } from 'vue'

import type { User } from '@/models/user.ts'
import { useAuthStore } from '@/stores/auth.ts'

export type TidioIdentity = {
  distinct_id: number
  email: string | undefined
  name: string | undefined
  phone: string | undefined
  city: string | undefined
  country: string | undefined
}

declare global {
  interface Window {
    tidioChatApi: {
      setVisitorData: (data: TidioIdentity | undefined) => void
    }
  }
}

declare global {
  interface Document {
    tidioIdentify: TidioIdentity | undefined
  }
}

export default {
  async install() {
    if (import.meta.env.VITE_TIDIO) {
      const authStore = useAuthStore()

      const user_raw = (await Preferences.get({ key: 'user' })).value
      const user: User | undefined = user_raw ? JSON.parse(user_raw) : undefined

      document.tidioIdentify = user
        ? {
            city: undefined,
            country: user.country.iso_alpha_2.toUpperCase(),
            distinct_id: user.id,
            email: user.email ?? undefined,
            name: user.name ?? undefined,
            phone: undefined,
          }
        : undefined

      const script = document.createElement('script')
      script.src = `https://code.tidio.co/${import.meta.env.VITE_TIDIO}.js`
      script.async = true
      script.onload = function () {}
      document.body.appendChild(script)

      watch(
        () => authStore.user,
        () => {
          if (window.tidioChatApi) {
            if (authStore.user) {
              window.tidioChatApi.setVisitorData({
                city: undefined,
                country: authStore.user.country.iso_alpha_2.toUpperCase(),
                distinct_id: authStore.user.id,
                email: authStore.user.email ?? undefined,
                name: authStore.user.name ?? undefined,
                phone: undefined,
              } as TidioIdentity)
            } else {
              window.tidioChatApi.setVisitorData(undefined)
            }
          } else {
            if (authStore.user) {
              document.tidioIdentify = {
                city: undefined,
                country: authStore.user.country.iso_alpha_2.toUpperCase(),
                distinct_id: authStore.user.id,
                email: authStore.user.email ?? undefined,
                name: authStore.user.name ?? undefined,
                phone: undefined,
              }
            } else {
              document.tidioIdentify = undefined
            }
          }
        },
      )
    }
  },
}
