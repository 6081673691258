<script setup lang="ts">
import { useNow } from '@vueuse/core'
import * as d3 from 'd3'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { computed, onMounted, onUnmounted, onUpdated, ref } from 'vue'
import type { PropType } from 'vue'

import { BudgetsTimeframe } from '@/enums/budgets-timeframe.ts'

dayjs.extend(advancedFormat)

const props = defineProps({
  data: {
    type: Object as PropType<DataSeries[]>,
    required: true,
  },
  timeframe: {
    type: String as PropType<BudgetsTimeframe>,
    required: true,
  },
  uuid: {
    type: String,
    required: true,
  },
  grid: {
    type: Boolean,
    required: false,
    default: true,
  },
  fill: {
    type: Boolean,
    required: false,
    default: false,
  },
})

export interface DataSeries {
  label: string
  positive_line_color: string
  positive_text_color: string
  negative_line_color?: string
  negative_text_color?: string
  positiveThreshold?: number
  values: DataPoint[]
  valueFormatter: (value: number) => string | number
  current_value?: DataPoint
  current_value_label?: string
  muted: boolean
  y_axis_from?: number
  y_axis_to?: number
}
export interface PotentialDataPoint {
  date: Date
  value?: number
}
export interface DataPoint {
  date: Date
  value: number
  label_override?: string
}

const now = useNow()
const end = computed(() => {
  return dayjs(now.value).set('milliseconds', 0).set('seconds', 0).set('minutes', 0)
})
const start = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const days: any = {
    [BudgetsTimeframe.PAST_7_DAYS]: 7,
    [BudgetsTimeframe.PAST_MONTH]: Math.floor(365.25 / 12),
    [BudgetsTimeframe.PAST_QUARTER]: Math.floor(365.25 / 4),
    [BudgetsTimeframe.PAST_YEAR]: Math.floor(365.25),
  }
  return end.value.subtract(days[props.timeframe], 'days')
})

const chartContainer = ref<HTMLElement | undefined>()

const render = () => {
  if (!chartContainer.value) return

  const margin = { top: 1, right: 0, bottom: props.grid ? 20 : 1, left: 0 }
  const width = chartContainer.value.offsetWidth - margin.left - margin.right
  const height = chartContainer.value.offsetHeight - margin.top - margin.bottom

  d3.select(chartContainer.value).select('svg').remove()

  const svg = d3
    .select(chartContainer.value)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`)

  const xScale = d3.scaleTime().domain([start.value, end.value]).range([0, width])

  if (props.grid) {
    const gX = svg
      .append('g')
      .attr('class', 'text-white/30')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3
          .axisBottom<Date>(xScale)
          .ticks(
            props.timeframe === BudgetsTimeframe.PAST_7_DAYS
              ? d3.timeDay.every(1)
              : props.timeframe === BudgetsTimeframe.PAST_MONTH
                ? d3.timeMonday.every(1)
                : d3.timeMonth.every(1),
          )
          .tickFormat((date: Date): string => {
            const formatDay = (() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const formats: any = {
                [BudgetsTimeframe.PAST_7_DAYS]: '%a',
                [BudgetsTimeframe.PAST_MONTH]: '%b %d',
                [BudgetsTimeframe.PAST_QUARTER]: '%b',
                [BudgetsTimeframe.PAST_YEAR]: '%b',
              }

              return d3.timeFormat(formats[props.timeframe])
            })()

            return formatDay(date)
          }),
      )
    gX.select('.domain').remove()
    gX.selectAll('.tick line').remove()

    svg
      .selectAll('xGrid')
      .data(
        props.timeframe === BudgetsTimeframe.PAST_7_DAYS
          ? d3.timeDay.range(start.value.toDate(), end.value.toDate())
          : props.timeframe === BudgetsTimeframe.PAST_MONTH
            ? d3.timeMonday.range(start.value.toDate(), end.value.toDate())
            : d3.timeMonth.range(start.value.toDate(), end.value.toDate()),
      )
      .join('line')
      .attr('x1', (d: Date) => xScale(d))
      .attr('x2', (d: Date) => xScale(d))
      .attr('y1', 0)
      .attr('y2', height)
      .attr('stroke', '#191919')
      .attr('stroke-width', 1)
  }
  props.data.forEach((series) => {
    const yScale = d3
      .scaleLinear()
      .domain([
        series.y_axis_from ?? Math.floor(d3.min(series.values, (d) => d.value)!),
        series.y_axis_to ?? Math.ceil(d3.max(series.values, (d) => d.value)!),
      ])
      .nice()
      .range([height, 0])

    if (props.fill) {
      const area = d3
        .area<DataPoint>()
        .x((d: DataPoint) => xScale(d.date))
        .y0(height)
        .y1((d: DataPoint) => yScale(d.value))
        .curve(d3.curveLinear)
      svg
        .append('path')
        .datum(series.values)
        .attr('fill', series.positive_line_color)
        .style('opacity', 0.15)
        .attr('d', area)
    }

    const line = d3
      .line<DataPoint>()
      .x((d: DataPoint) => xScale(d.date))
      .y((d: DataPoint) => yScale(d.value))
      .curve(d3.curveLinear)

    const positiveThresholdY =
      series.positiveThreshold !== undefined ? yScale(series.positiveThreshold) : height

    // Draw the positive line
    svg
      .append('path')
      .datum(series.values)
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', series.positive_line_color)
      .attr('stroke-width', 1.4)
      .attr('opacity', series.muted ? 0.3 : 1)
      .attr('d', line)
      .attr('clip-path', `url(#positive_${props.uuid}_${convertToSnakeCase(series.label)})`)
    // Draw the negative line
    svg
      .append('path')
      .datum(series.values)
      .attr('class', 'line')
      .attr('fill', 'none')
      .attr('stroke', series.negative_line_color ?? series.positive_line_color)
      .attr('stroke-width', 1.4)
      .attr('opacity', series.muted ? 0.3 : 1)
      .attr('d', line)
      .attr('clip-path', `url(#negative_${props.uuid}_${convertToSnakeCase(series.label)})`)

    // Define the clipPaths
    svg
      .append('clipPath')
      .attr('id', `positive_${props.uuid}_${convertToSnakeCase(series.label)}`) // Use a fixed ID for simplicity
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', positiveThresholdY - 1)

    svg
      .append('clipPath')
      .attr('id', `negative_${props.uuid}_${convertToSnakeCase(series.label)}`) // Use a fixed ID for simplicity
      .append('rect')
      .attr('x', 0)
      .attr('y', positiveThresholdY)
      .attr('width', width)
      .attr('height', height - positiveThresholdY - 1)
  })

  d3.selectAll(`.${props.uuid}`).remove()

  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', `tooltip ${props.uuid}`)
    .style('opacity', 0)
    .style('display', 'none')

  const focusLine = svg
    .append('line')
    .attr('class', 'focus-line')
    .style('opacity', 0)
    .attr('y1', 0)
    .attr('y2', height)

  const bisect = d3.bisector((d: DataPoint) => d.date).left

  const handleMouseMove = (event: MouseEvent) => {
    const xMousePosition = xScale.invert(d3.pointer(event)[0])
    const pointIndex = bisect(props.data[0].values, xMousePosition, 1)
    const prevPoint = props.data[0].values[pointIndex - 1]
    const nextPoint = props.data[0].values[pointIndex] ?? prevPoint
    const point =
      +xMousePosition - +prevPoint.date > +nextPoint.date - +xMousePosition ? nextPoint : prevPoint

    const parts = props.data.map((series) => {
      const seriesPoint = series.values.find((p) => p.date.getTime() === point.date.getTime())
      return seriesPoint
        ? `<div class="font-mono flex items-center justify-between space-x-3 ${
            series.muted ? `opacity-40` : ``
          }">
            <span style="color: ${
              series.positiveThreshold === undefined ||
              series.positiveThreshold <= seriesPoint.value
                ? series.positive_text_color
                : (series.negative_text_color ?? series.positive_text_color)
            }">${seriesPoint.label_override ?? series.label}</span>
            <span>${series.valueFormatter(seriesPoint.value)}</span>
          </div>`
        : ''
    })

    tooltip.html(
      `<div class="flex flex-col">
        <div class="font-semibold">${dayjs(point.date).format('Do MMM YYYY')}</div>
        ${parts.join('')}
      </div>`,
    )

    const tooltipWidth = 250
    const pageWidth = document.body.clientWidth
    let left = event.pageX + 10
    if (event.pageX + tooltipWidth > pageWidth - margin.right) {
      left = event.pageX - tooltipWidth - 10
    }

    tooltip.style('left', `${left}px`).style('top', `${event.pageY - 28}px`)
    focusLine.attr('x1', xScale(point.date)).attr('x2', xScale(point.date))
  }

  svg
    .append('rect')
    .attr('width', width)
    .attr('height', height)
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .on('mouseover', () => {
      tooltip.style('opacity', 1).style('display', 'block')
      focusLine.style('opacity', 0.2)
    })
    .on('mouseout', () => {
      tooltip.style('opacity', 0).style('display', 'none')
      focusLine.style('opacity', 0)
    })
    .on('mousemove', handleMouseMove)
}

onMounted(() => {
  window.addEventListener('resize', render)
  render()
})
onUpdated(() => {
  render()
})
onUnmounted(() => {
  window.removeEventListener('resize', render)
})

function convertToSnakeCase(text: string): string {
  return text
    .toLowerCase() // Convert the text to lowercase
    .replace(/\s+/g, '_') // Replace spaces with underscores
    .trim() // Remove any leading or trailing whitespace
}
</script>

<template>
  <div ref="chartContainer" class="chart-container"></div>
</template>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
svg {
  width: 100%;
  height: 100%;
}
</style>
