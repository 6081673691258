<script setup lang="ts">
import dayjs from 'dayjs'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import bg from '@/assets/bg.png'
import PageLayout from '@/ui/components/page-layout.vue'
import TermsContent from '@/ui/components/terms-content.vue'
import { api } from '@/helpers/api.ts'
import { useAuthStore } from '@/stores/auth.ts'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()

const loading = ref<boolean>(false)

const accept = () => {
  if (loading.value) {
    return
  }

  loading.value = true

  api.put('terms/accept').then(async () => {
    authStore.user!.terms_agreed_at = dayjs().unix()

    if (route.query.for) {
      await router.replace(JSON.parse(atob(route.query.for as string)))
    } else {
      await router.replace({
        name: 'workspaces',
      })
    }

    loading.value = false
  })
}
const decline = async () => {
  await router.push({
    name: 'home',
  })
}
</script>
<template>
  <div
    :style="`
      background-color: #003335;
      background-image: url(${bg});
      background-size: cover;
      background-position: top;
      background-attachment: fixed;
      background-blend-mode: soft-light;
    `"
  >
    <div class="flex min-h-screen" style="background-color: rgba(0, 0, 0, 0.82)">
      <page-layout :tabs="false" :centered="true" v-if="authStore.user">
        <div class="flex flex-col space-y-7">
          <h2 class="text-3xl font-medium">Terms of service</h2>
          <div class="h-[400px] w-full max-w-3xl overflow-y-scroll font-mono">
            <terms-content></terms-content>
          </div>
          <div class="flex justify-end space-x-4">
            <button class="button muted large rounded-sm" @click="decline">Decline</button>
            <button
              :class="`primary button large w-[300px] rounded-sm ${loading ? `disabled` : ``}`"
              @click="accept"
            >
              Accept
            </button>
          </div>
        </div>
      </page-layout>
    </div>
  </div>
</template>
