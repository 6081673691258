<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import dashboard from '@/assets/docs/dashboard.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      The dashboard is your main screen in Charter where you can see your budgets and get a quick
      overview of how your ads are doing. Budgets are presented within their budget groups, with
      unassigned budgets at the bottom.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="dashboard" />
    </div>
  </div>
</template>
