<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import { event } from '@/helpers/events.ts'

const { t } = useI18n()
</script>
<template>
  <div
    class="container flex flex-col items-center space-y-12 text-center sm:space-y-16"
    id="pricing"
  >
    <span class="block w-full font-sans text-5xl font-medium leading-tight">{{
      t('landing.pricing.title')
    }}</span>
    <div
      class="flex max-w-[800px] flex-col items-center space-y-8 text-2xl leading-normal sm:space-y-10"
    >
      <span class="block text-balance text-3xl">{{ t('landing.pricing.description') }}</span>
    </div>
    <div class="z-0 flex flex-col items-center space-y-6 pt-2">
      <router-link
        :to="{ name: 'sign-up' }"
        :class="`extra large very magic button h-[148px] w-[400px] space-x-4 text-2xl`"
        @click="event('click-sign-up', { location: 'pricing' })"
      >
        <span>{{ t('landing.pricing.cta') }}</span>
      </router-link>
      <span class="text-base font-normal text-zinc-400">{{
        t('landing.pricing.no-card-needed')
      }}</span>
    </div>
  </div>
</template>
