<script setup lang="ts">
import { useCookiebot } from '@ambitiondev/vue-cookiebot'
import { onMounted } from 'vue'

import ClientError from '@/ui/components/error/client-error.vue'
import ConnectionAbortError from '@/ui/components/error/connection-abort-error.vue'
import ConnectionError from '@/ui/components/error/connection-error.vue'
import ConnectionTimeoutError from '@/ui/components/error/connection-timeout-error.vue'
import ServerError from '@/ui/components/error/server-error.vue'
import UserError from '@/ui/components/error/user-error.vue'
import { loadMetaPixel } from '@/helpers/meta-pixel.ts'
import { useAccountStore } from '@/stores/account.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const { consentBanner } = useCookiebot()

onMounted(() => {
  if (import.meta.env.VITE_META_PIXEL_ID) {
    loadMetaPixel(import.meta.env.VITE_META_PIXEL_ID)
  }
  consentBanner()
})
useAccountStore()
useWorkspaceStore()
</script>
<template>
  <connection-error></connection-error>
  <connection-abort-error></connection-abort-error>
  <connection-timeout-error></connection-timeout-error>
  <server-error></server-error>
  <client-error></client-error>
  <user-error></user-error>
  <div id="app" class="flex min-h-screen flex-col">
    <router-view />
  </div>
</template>
