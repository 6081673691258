<script setup lang="ts">
import { watchOnce } from '@vueuse/core'
import { useRouter } from 'vue-router'

import LoadingBar from '@/ui/components/loading-bar.vue'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const workspaceStore = useWorkspaceStore()
const router = useRouter()

const loaded = async () => {
  const latest_workspace = await workspaceStore.latestWorkspace()

  if (latest_workspace) {
    await router.replace({
      name: 'workspace',
      params: {
        workspace: latest_workspace.id,
      },
    })
  } else {
    // todo: create a workspace?
  }
}

if (workspaceStore.workspaces) {
  loaded()
} else {
  watchOnce(() => workspaceStore.workspaces, loaded)
}
</script>
<template>
  <div class="flex grow flex-col">
    <div class="flex grow items-center justify-center">
      <loading-bar />
    </div>
  </div>
</template>
