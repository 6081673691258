<script setup lang="ts">
import { ArrowLeftIcon, ArrowRightIcon } from '@radix-icons/vue'
import type { PropType } from 'vue'

import type { Item } from '@/ui/components/docs/contents.ts'

const props = defineProps({
  prev: {
    type: Object as PropType<Item>,
    required: false,
    default: undefined,
  },
  next: {
    type: Object as PropType<Item>,
    required: false,
    default: undefined,
  },
})
</script>
<template>
  <div
    class="sticky bottom-0 hidden h-[86px] w-full select-none items-center justify-between border-t-[1px] border-white/10 bg-black/30 lg:flex"
  >
    <router-link
      :to="props.prev.route ?? { name: 'docs' }"
      class="flex h-full items-center space-x-4 px-12 opacity-50 transition-all duration-75 hover:opacity-100"
      v-if="props.prev"
    >
      <ArrowLeftIcon class="h-5 w-5"></ArrowLeftIcon>
      <span>{{ props.prev.title }}</span>
    </router-link>
    <div v-else></div>
    <router-link
      :to="props.next.route ?? { name: 'docs' }"
      class="flex h-full items-center space-x-4 px-12 font-medium transition-all duration-75 hover:bg-white hover:text-black"
      v-if="props.next"
    >
      <span>{{ props.next.title }}</span>
      <ArrowRightIcon class="h-5 w-5"></ArrowRightIcon>
    </router-link>
    <div v-else></div>
  </div>
</template>
