<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

import DialogBody from '@/ui/components/dialog-body.vue'
import DialogTitlebar from '@/ui/components/dialog-titlebar.vue'
import StyledButton from '@/ui/components/form/styled-button.vue'
import { ButtonType } from '@/enums/button-type.ts'
import { api } from '@/helpers/api.ts'
import { waitFor } from '@/helpers/wait-for.ts'
import type { AdChannel } from '@/models/ad-channel.ts'
import type { GoogleAdAccountOption } from '@/models/google-ad-account-option.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const emit = defineEmits<{
  (e: 'complete'): void
  (e: 'cancel'): void
}>()
const props = defineProps({
  accessToken: {
    type: String,
    required: true,
  },
  refreshToken: {
    type: String,
    required: true,
  },
  adChannels: {
    type: Array as PropType<GoogleAdAccountOption[]>,
    required: true,
  },
})
const workspaceStore = useWorkspaceStore()

const route = useRoute()

const pending = ref<boolean>(false)
const errors = ref<FormErrors>({})

type FormErrors = {
  ad_channel?: string
}
const selected_ad_channel = ref<GoogleAdAccountOption | undefined>(undefined)

const save = async () => {
  if (pending.value) {
    return
  }
  pending.value = true

  if (props.adChannels.length === 1) {
    selected_ad_channel.value = props.adChannels[0]
  }

  if (selected_ad_channel.value === undefined) {
    errors.value.ad_channel = 'Please select an ad channel'
    pending.value = false
    return
  }
  if (!workspaceStore.workspace) {
    await waitFor(() => workspaceStore.workspace)
  }
  api
    .put(`ad-channel/google-confirm`, {
      workspace_id: parseInt(route.params.workspace as string),
      customer_id: selected_ad_channel.value.customer_id,
      access_token: props.accessToken,
      refresh_token: props.refreshToken,
      ad_channel_id: selected_ad_channel.value.id,
      currency_code: selected_ad_channel.value.currency_code,
      timezone: selected_ad_channel.value.timezone,
      name: selected_ad_channel.value.name,
      manager: selected_ad_channel.value.manager,
    })
    .then((data: unknown) => {
      workspaceStore.mergeAdChannel(data as AdChannel, workspaceStore.workspace!)
      emit('complete')
    })
}
</script>
<template>
  <div class="dialog-box">
    <dialog-titlebar @close="emit('cancel')" title="Select ad channel"></dialog-titlebar>
    <dialog-body>
      <div class="flex w-[400px] flex-col space-y-5">
        <select class="w-full" v-model="selected_ad_channel">
          <option :value="ad_channel" v-for="ad_channel in props.adChannels" :key="ad_channel.id">
            {{ ad_channel.name ? ad_channel.name : ad_channel.id }}
          </option>
        </select>
        <div class="flex justify-start pl-0.5">
          <styled-button :type="ButtonType.PRIMARY" :submitting="pending" @click="save"
            >Continue</styled-button
          >
        </div>
      </div>
    </dialog-body>
  </div>
</template>
