<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import Toast from '@/ui/components/toast.vue'
import { ToastVariant } from '@/enums/toast-variant.ts'

const route = useRoute()

onMounted(() => {
  window.addEventListener(`server-error`, show)
})
onBeforeUnmount(() => {
  window.removeEventListener(`server-error`, show)
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const show = (e: any) => {
  if (route.matched[0].name === 'home') {
    return
  }
  message.value = e.detail.message
  open.value = true
}

const open = ref(false)
const message = ref<string | undefined>()
</script>
<template>
  <toast
    v-model="open"
    :variant="ToastVariant.ERROR"
    title="Something unexpected happened"
    :description="
      message ||
      `Our developers have been notified. If problems continues, we are really sorry! It will be resolved soon.`
    "
    :short-description="
      message ?? `Something unexpected happened, our developers have been notified.`
    "
  ></toast>
</template>
