<script setup lang="ts"></script>
<template>
  <div
    class="fixed inset-x-0 bottom-0 flex flex-col items-center justify-center space-y-5 px-10 py-10 bg-black/50"
  >
    <router-link :to="{ name: 'sign-in' }" class="w-full">
      <button class="button h-[86px] w-full border-0 text-3xl font-light">Sign in</button>
    </router-link>
    <router-link :to="{ name: 'sign-up' }" class="w-full">
      <button class="magic button h-[66px] w-full text-3xl font-medium">30 day free trial →</button>
    </router-link>
  </div>
</template>
