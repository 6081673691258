<script setup lang="ts">
import DialogBody from '@/ui/components/dialog-body.vue'
import DialogTitlebar from '@/ui/components/dialog-titlebar.vue'
import type { Country } from '@/models/country.ts'
import { useCountryStore } from '@/stores/country.ts'
import { useInternationalisationStore } from '@/stores/internationalisation.ts'

const emit = defineEmits<{
  (e: 'cancel'): void
}>()

const countryStore = useCountryStore()
const internationalisationStore = useInternationalisationStore()

const chooseCountry = (country: Country) => {
  internationalisationStore.chooseCountry(country)
}
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar @close="emit('cancel')" title="Select country"></dialog-titlebar>
    <dialog-body>
      <div class="grid w-full grid-cols-4 gap-0 pb-4 pt-4 md:min-w-[650px] md:grid-cols-8 md:gap-8">
        <div
          @click="
            () => {
              chooseCountry(country)
              emit('cancel')
            }
          "
          v-for="country in countryStore.countries"
          :key="country.id"
          class="flex cursor-pointer items-center justify-center p-3"
        >
          <img
            class="h-auto w-8 cursor-pointer"
            :src="`/flag/rectangle/${country.iso_alpha_2}.svg`"
            :alt="country.english_name"
          />
        </div>
      </div>
    </dialog-body>
  </div>
</template>
