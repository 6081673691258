<script setup lang="ts">
import { ChevronLeftIcon } from '@radix-icons/vue'

import Tabs from '@/ui/components/workspace/tabs.vue'

const emit = defineEmits<{
  (e: 'back'): void
}>()

const props = defineProps({
  tabs: {
    type: Boolean,
    required: false,
    default: false,
  },
  centered: {
    type: Boolean,
    required: false,
    default: false,
  },
  hasBackButton: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>
<template>
  <div class="relative flex grow flex-col">
    <div
      v-if="props.hasBackButton"
      class="absolute left-0 top-0 transform cursor-pointer p-8 opacity-30 transition-all duration-75 hover:opacity-100"
      @click="emit('back')"
    >
      <ChevronLeftIcon class="h-10 w-auto"></ChevronLeftIcon>
    </div>
    <div class="flex-none" v-if="props.tabs">
      <tabs class="mb-7 mt-0"></tabs>
    </div>
    <div :class="`flex grow ${centered ? `items-center justify-center` : ``}`">
      <slot></slot>
    </div>
  </div>
</template>
