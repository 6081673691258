<script setup lang="ts">
import { useAuthStore } from '@/stores/auth.ts'

const authStore = useAuthStore()
</script>
<template>
  <div class="flex flex-col space-y-2 px-5 py-2.5" v-if="authStore.user">
    <span class="font-medium" v-if="authStore.user.name">{{ authStore.user!.name }}</span>
    <span class="text-sm">{{ authStore.user?.email }}</span>
  </div>
</template>
