<script setup lang="ts">
import DialogBody from '@/ui/components/dialog-body.vue'
import DialogTitlebar from '@/ui/components/dialog-titlebar.vue'
import ConnectAdChannelInner from '@/ui/components/workspace/ad-channels/connect-ad-channel-options.vue'

const emit = defineEmits<{
  (e: 'cancel'): void
}>()
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('cancel')"
      title="Select an ad channel to connect"
    ></dialog-titlebar>
    <dialog-body>
      <connect-ad-channel-inner @cancel="emit('cancel')"></connect-ad-channel-inner>
    </dialog-body>
  </div>
</template>
