<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import target_metrics from '@/assets/docs/target-metrics.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Within each budget group, you select a key metric. This is the objective for this group of
      budgets, Charter will optimise for the key metric you select within its budget group.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="target_metrics" />
    </div>
    <p>You can select from the following target metrics:</p>
    <ul>
      <li><span class="inline font-bold"> Revenue </span> e.g. for sales budgets</li>
      <li><span class="inline font-bold"> Impressions </span> e.g. for brand awareness budgets</li>
      <li><span class="inline font-bold"> Reach </span> e.g. for brand awareness budgets</li>
      <li>
        <span class="inline font-bold"> Clicks </span> e.g. for trying to catch as much of your
        audience’s attention as possible
      </li>
      <li>
        <span class="inline font-bold"> Return on ad spend </span> e.g. to maximise the efficiency
        of your ads
      </li>
      <li>
        <span class="inline font-bold"> Cost per purchase </span> e.g. similar to ROAS for
        efficiency but better for products with a narrower price range
      </li>
      <li>
        <span class="inline font-bold"> Average purchase value </span> e.g. to maximise budgets
        which result in high order values
      </li>
    </ul>
    <p>
      Is there a key metric you’d like to use but isn’t currently an option? Drop us a message to
      let us know!
    </p>
  </div>
</template>
