import './css/main.css'
import './helpers/api.ts'

import { cookieBot } from '@ambitiondev/vue-cookiebot'
import { Preferences } from '@capacitor/preferences'
import * as Sentry from '@sentry/vue'
import Echo from 'laravel-echo'
import { createPinia } from 'pinia'
import Pusher from 'pusher-js'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'

import { api } from '@/helpers/api.ts'
import type { Country } from '@/models/country.ts'
import type { Language } from '@/models/language.ts'
import tidioPlugin from '@/plugins/tidio'
import router from '@/router'

import en_GB from '../locales/en_GB.json'
import en_US from '../locales/en_US.json'
import App from './App.vue'
import posthogPlugin from './plugins/posthog'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Pusher: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Echo: any
    tz: string
    token: string | undefined
    budgets_timeframe: string | undefined
    chosen_country: Country | undefined
    chosen_language: Language | undefined
    remembered_locale: string | undefined
  }
}

const loadApp = async () => {
  const app = createApp(App)

  if (import.meta.env.VITE_SENTRY_ENABLED === 'true' && import.meta.env.VITE_SENTRY_DSN?.length) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.usecharter\.io/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }

  window.Pusher = Pusher
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    wssPort: import.meta.env.VITE_PUSHER_PORT,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'local',
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: { name: string }) => {
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        authorize: (socketId: string, callback: any) => {
          api
            .post(`broadcasting/auth`, {
              socket_id: socketId,
              channel_name: channel.name,
            })
            .then((response) => {
              callback(null, response)
            })
            .catch(callback)
        },
      }
    },
  })
  window.Echo.connector.pusher.connection.bind(
    'state_change',
    function (states: { current: string }) {
      if (states.current === 'disconnected') {
        window.Echo.connector.pusher.connect()
      }
    },
  )
  window.token = (await Preferences.get({ key: 'token' })).value ?? undefined
  window.budgets_timeframe =
    (await Preferences.get({ key: 'budgets_timeframe' })).value ?? undefined

  const chosen_country = (await Preferences.get({ key: 'chosen_country' })).value
  window.chosen_country = chosen_country ? (JSON.parse(chosen_country) as Country) : undefined

  const chosen_language = (await Preferences.get({ key: 'chosen_language' })).value

  window.chosen_language = chosen_language ? (JSON.parse(chosen_language) as Language) : undefined

  window.remembered_locale =
    (await Preferences.get({ key: 'remembered_locale' })).value ?? undefined

  if (import.meta.env.VITE_COOKIEBOT_ID) {
    app.use(cookieBot, {
      cookieBotId: import.meta.env.VITE_COOKIEBOT_ID,
      culture: 'en',
    })
  }
  app.use(
    createI18n({
      locale: window.remembered_locale || navigator.language.replace('-', '_'),
      fallbackLocale: 'en_US',
      messages: { en_GB, en_US },
    }),
  )
  app.use(createPinia())
  app.use(router)
  app.use(posthogPlugin)
  app.use(tidioPlugin)
  app.mount('#app')
}

loadApp().then(() => {})
