<script setup lang="ts">
import { DropdownMenuItem } from 'radix-vue'

import { useAuthStore } from '@/stores/auth.ts'

const authStore = useAuthStore()
</script>
<template>
  <DropdownMenuItem
    value="Sign out"
    class="group relative flex cursor-pointer select-none items-center rounded-sm px-5 py-2.5 leading-none outline-none transition-colors data-[disabled]:pointer-events-none bg-black/30 hover:bg-white/20"
    @click="authStore.signOut"
  >
    Sign out
  </DropdownMenuItem>
</template>
