<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import budget_change_automatic from '@/assets/docs/budget-change-automatic.png'
import budget_change_manual from '@/assets/docs/budget-change-manual.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">Charter's algorithm can run either automatically or manually.</p>
    <p class="inline font-bold">Automatically</p>
    <p>
      This is completely hands off, Charter will make budget changes without you needing to do
      anything, and you can review historic changes in the algorithm run log.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_change_automatic" />
    </div>
    <p class="inline font-bold">Manually</p>
    <p>
      The algorithm will run but it won’t make budget changes without your approval. As with
      automatic, you can review historic changes in the log.
    </p>
    <p>You can set budget change approval prefernces within each budget group.</p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_change_manual" />
    </div>
    <p>
      When there’s a budget change to approve you’ll get a email notification which you can click
      through to approve or reject. Alternatively you can simply click the notification in each
      budget group that requires budget approval and approve or reject from there.
    </p>
  </div>
</template>
