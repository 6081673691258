<script setup lang="ts">
import { BadgeType } from '@/enums/badge-type.ts'

const props = defineProps({
  n: {
    type: Number,
    required: false,
    default: undefined,
  },
  type: {
    type: String,
    required: false,
    default: BadgeType.NEUTRAL,
  },
})
</script>
<template>
  <span v-if="props.n && props.n >= 1" :class="`number-badge ${props.type}`">{{ props.n }}</span>
</template>
