<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  comingSoon: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>
<template>
  <div
    :class="`${props.comingSoon ? `bg-white/3` : `bg-[#12171e] `} group flex aspect-[11/7] w-full flex-col items-center justify-center space-y-5 rounded-xl px-9 pb-10 pt-9 transition-all duration-200`"
  >
    <div
      :class="`${props.comingSoon ? `opacity-40 transition-all duration-300 group-hover:opacity-0` : ``}`"
    >
      <slot name="icon"></slot>
    </div>
    <div
      :class="`flex flex-col space-y-3.5 transition-all duration-300 ${props.comingSoon ? `opacity-40 group-hover:opacity-0` : ``}`"
    >
      <span class="font-regular select-none font-sans text-3xl">{{ props.title }}</span>
    </div>
    <span
      :class="`absolute select-none opacity-0 transition-all duration-200 ${comingSoon ? `group-hover:opacity-100` : ``} pb-3`"
      >{{ t('landing.integrations.coming-soon') }}</span
    >
  </div>
</template>
