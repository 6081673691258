<script setup lang="ts">
import { HamburgerMenuIcon } from '@radix-icons/vue'
import {
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from 'radix-vue'
import { ref } from 'vue'

import NumberBadge from '@/ui/components/number-badge.vue'
import Account from '@/ui/components/workspace/user-menu/options/account.vue'
import SignOut from '@/ui/components/workspace/user-menu/options/sign-out.vue'
import User from '@/ui/components/workspace/user-menu/options/user.vue'
import Separator from '@/ui/components/workspace/user-menu/separator.vue'
import { useAccountStore } from '@/stores/account.ts'
import { useAuthStore } from '@/stores/auth.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const isOpen = ref<boolean>(false)

const workspaceStore = useWorkspaceStore()
const accountStore = useAccountStore()
const authStore = useAuthStore()
</script>
<template>
  <DropdownMenuRoot
    v-if="authStore.user"
    :open="isOpen"
    @update:open="
      (payload: boolean) => {
        isOpen = payload
      }
    "
    :modal="false"
  >
    <DropdownMenuTrigger
      class="group relative inline-flex aspect-square w-12 cursor-pointer items-center justify-center rounded outline-none transition-colors"
      aria-label="Customise options"
    >
      <HamburgerMenuIcon
        class="h-auto w-6 text-white/30 transition-all duration-75 group-hover:text-white"
      ></HamburgerMenuIcon>
      <!--      <PersonIcon class="h-6 w-6 cursor-pointer text-zinc-700" />-->
      <number-badge
        v-if="workspaceStore.menuNotificationCount >= 1 && !isOpen"
        :n="workspaceStore.menuNotificationCount"
        class="absolute -top-0.5 left-8 cursor-pointer"
      ></number-badge>
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        class="data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade flex min-w-[272px] flex-col space-y-1 rounded border border-white/20 bg-[#00050b] px-1.5 py-2.5 shadow-[0_2px_30px_-15px_rgb(0,0,0,0.2)] outline outline-4 will-change-[opacity,transform]"
        :side-offset="10"
        align="end"
      >
        <template v-if="authStore.user?.email">
          <user></user>
          <separator></separator>
        </template>
        <template v-if="accountStore.accounts?.length ?? 0 >= 1">
          <account></account>
          <separator></separator>
        </template>
        <sign-out></sign-out>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
