import { Preferences } from '@capacitor/preferences'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { api } from '@/helpers/api.ts'
import { nullsToUndefined } from '@/helpers/nulls-to-undefined.ts'
import type { AuthenticationSuccess } from '@/models/authentication-result.ts'
import type { GuestInit } from '@/models/guest-init.ts'
import type { User, UserRaw } from '@/models/user.ts'
import type { UserInit } from '@/models/user-init.ts'
import { useAccountStore } from '@/stores/account.ts'
import { useCountryStore } from '@/stores/country.ts'
import { useCurrencyStore } from '@/stores/currency.ts'
import { useCurrencyRateStore } from '@/stores/currency-rates.ts'
import { useLanguageStore } from '@/stores/language.ts'
import { useTimezoneStore } from '@/stores/timezones.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'
import { useInternationalisationStore } from '@/stores/internationalisation.ts'
// import { showConnectionErrorModal, showServerErrorModal } from '@/functions/errors'

export const useAuthStore = defineStore('auth', () => {
  const router = useRouter()
  const route = useRoute()
  const workspaceStore = useWorkspaceStore()
  const languageStore = useLanguageStore()
  const accountStore = useAccountStore()
  const currencyStore = useCurrencyStore()
  const countryStore = useCountryStore()
  const timezoneStore = useTimezoneStore()
  const currencyRateStore = useCurrencyRateStore()
  const internationalisationStore = useInternationalisationStore()

  // stored token

  const user = ref<User | undefined>(undefined)
  const initialised = ref<boolean>(false)
  const whenInitialised = computed(() => initialised)
  const token = ref<string | undefined>(window.token)
  delete window.token

  watch(token, () => {
    if (token.value) {
      Preferences.set({
        key: 'token',
        value: `${token.value}`,
      }).then(() => {})
    } else {
      Preferences.remove({ key: 'token' }).then(() => {})
    }
  })

  watch(user, () => {
    if (user.value) {
      Preferences.set({
        key: 'user',
        value: JSON.stringify(user.value),
      }).then(() => {})
    } else {
      Preferences.remove({ key: 'user' }).then(() => {})
    }
  })

  // user data

  const convertRawUser = (user_raw: UserRaw): User => {
    return nullsToUndefined({
      ...user_raw,
      language: languageStore.find(user_raw.language_id),
      country: countryStore.find(user_raw.country_id),
    }) as User
  }

  const load = (data: UserInit) => {
    currencyStore.load(data.currencies)
    languageStore.load(data.languages)
    currencyRateStore.load(data.currency_rates)
    countryStore.load(data.countries)
    internationalisationStore.setIpCountry(data.country_id)
    timezoneStore.load(data.timezones)
    user.value = convertRawUser(data.user)
    accountStore.setAccounts(data.accounts)
    workspaceStore.setWorkspaces(data.workspaces)
    initialised.value = true
  }
  const loadGuest = (data: GuestInit) => {
    currencyStore.load(data.currencies)
    languageStore.load(data.languages)
    currencyRateStore.load(data.currency_rates)
    countryStore.load(data.countries)
    internationalisationStore.setIpCountry(data.country_id)
    timezoneStore.load(data.timezones)
    initialised.value = true
  }
  const clear = () => {
    token.value = undefined
    user.value = undefined
    workspaceStore.clearWorkspaces()
    accountStore.clearAccounts()
    initialised.value = false
  }
  const initialise = () => {
    if (token.value) {
      api
        .get(`init/authenticated?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
        .then((data: unknown) => {
          load(data as UserInit)
        })
        .catch(() => {})
    } else {
      api
        .get(`init/unauthenticated?timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
        .then((data: unknown) => {
          loadGuest(data as GuestInit)
        })
    }
  }

  initialise()

  // authentication

  const signIn = async (authentication_success_response: AuthenticationSuccess) => {
    load(authentication_success_response.init)
    if (authentication_success_response.token) {
      token.value = authentication_success_response.token
    }
  }

  const signOut = async () => {
    api.delete(`auth/close`).finally(async () => {
      clear()
      initialise()

      if (route.name !== 'sign-in' && route.matched[0].name !== 'docs' && route.name !== 'home') {
        await router.push({ name: 'home' })
      }
    })
  }

  return {
    initialised,
    initialise,
    whenInitialised,
    user,
    convertRawUser,
    signIn,
    signOut,
    // userChanged,
    load,
    token,
  }
})
