import { api } from '@/helpers/api.ts'

export const event = async (
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any,
  account_id?: number,
  workspace_id?: number,
  ad_channel_id?: number,
  budget_id?: number,
) => {
  api.post(`/event`, {
    name,
    data: data ?? {},
    account_id,
    workspace_id,
    ad_channel_id,
    budget_id,
  })
}
