<script setup lang="ts">
const props = defineProps({
  padding: {
    type: Boolean,
    required: false,
    default: true,
  },
})
</script>
<template>
  <div :class="`${props.padding ? `px-5 pb-5` : ``}`">
    <slot></slot>
  </div>
</template>
