<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import DialogBody from '@/ui/components/dialog-body.vue'
import DialogTitlebar from '@/ui/components/dialog-titlebar.vue'
import InputCurrency from '@/ui/components/form/controls/input-currency.vue'
import FormGroup from '@/ui/components/form/form-group.vue'
import StyledButton from '@/ui/components/form/styled-button.vue'
import { ButtonType } from '@/enums/button-type.ts'
import { api } from '@/helpers/api.ts'
import { formatNumber } from '@/helpers/formatting.ts'
import type { Budget } from '@/models/budget.ts'
import type { Currency } from '@/models/currency.ts'
import type { WorkspaceRaw } from '@/models/workspace.ts'
import { useCurrencyStore } from '@/stores/currency.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const emit = defineEmits<{
  (e: 'close'): void
}>()
const currencyStore = useCurrencyStore()
const workspaceStore = useWorkspaceStore()

const props = defineProps({
  budget: {
    type: Object as PropType<Budget>,
    required: true,
  },
})

const currency = computed<Currency | undefined>(() => {
  if (currencyStore.currencies === undefined) {
    return undefined
  }
  return currencyStore.currencies[props.budget.currency_id]
})

const submit = () => {
  if (!currency.value) {
    return
  }
  if (submitting.value) {
    return
  }
  submitting.value = true

  validate()

  if (error.value) {
    submitting.value = false

    return
  }

  api
    .put('budget/update-budget', {
      budget_id: props.budget.id,
      effective_daily_budget: normalizeAndParseFloat(
        amount.value,
        currency.value!.thousands_separator,
        currency.value!.decimal_separator,
      ),
    })
    .then((data: unknown) => {
      workspaceStore.mergeWorkspaceRaw(data as WorkspaceRaw)
      emit('close')
    })
    .finally(() => {
      submitting.value = false
    })
}

const normalizeAndParseFloat = (
  value: string | number,
  thousands_separator: string,
  decimal_separator: string,
) => parseFloat(`${value}`.replace(thousands_separator, '').replace(decimal_separator, '.'))

const amount = ref<string>(`${formatNumber(props.budget.effective_daily_budget, 2)}`)
const submitting = ref<boolean>(false)
const error = ref<string | undefined>(undefined)

const validate = () => {
  if (amount.value === '') {
    error.value = 'Please enter a budget'
  } else {
    error.value = undefined
  }
}
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar @close="emit('close')" title="Change daily budget"></dialog-titlebar>
    <dialog-body
      ><div class="flex flex-col space-y-4">
        <form-group :border="false" :padded="false">
          <template #controls>
            <input-currency
              :class="``"
              :currency-id="props.budget.currency_id"
              v-model="amount"
              :readonly="submitting"
              @input="
                () => {
                  if (error) {
                    validate()
                  }
                }
              "
            ></input-currency>
          </template>
          <template #error>
            <span class="form-error" v-if="error">{{ error }}</span>
          </template>
        </form-group>
        <div class="pb-1.5 pt-4">
          <styled-button :type="ButtonType.PRIMARY" :submitting="submitting" @click="submit">
            Apply
          </styled-button>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
