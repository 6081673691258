import { Preferences } from '@capacitor/preferences'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import { BudgetsTimeframe } from '@/enums/budgets-timeframe.ts'
import { api } from '@/helpers/api.ts'
import { nullsToUndefined } from '@/helpers/nulls-to-undefined.ts'
import type { Account } from '@/models/account.ts'
import type { AdChannel } from '@/models/ad-channel.ts'
import type { Budget, BudgetRaw } from '@/models/budget.ts'
import type { Workspace, WorkspaceRaw } from '@/models/workspace.ts'
import type { WorkspaceRole, WorkspaceRoleRaw } from '@/models/workspace-role.ts'
import type { WorkspacesInAccount } from '@/models/workspaces-in-account.ts'
import { useAccountStore } from '@/stores/account.ts'
import { useAuthStore } from '@/stores/auth.ts'
import { useBudgetStore } from '@/stores/budget.ts'
import { useCurrencyStore } from '@/stores/currency.ts'

export const useWorkspaceStore = defineStore('workspace', () => {
  const route = useRoute()
  const accountStore = useAccountStore()
  const authStore = useAuthStore()
  const budgetStore = useBudgetStore()
  const currencyStore = useCurrencyStore()

  const convertRawWorkspaceRole = (workspace_role_raw: WorkspaceRoleRaw): WorkspaceRole => {
    return nullsToUndefined({
      ...workspace_role_raw,
      user: authStore.convertRawUser(workspace_role_raw.user),
      inviter: workspace_role_raw.inviter
        ? authStore.convertRawUser(workspace_role_raw.inviter)
        : undefined,
    }) as WorkspaceRole
  }

  const convertRawWorkspace = (workspace_raw: WorkspaceRaw): Workspace => {
    return nullsToUndefined({
      ...workspace_raw,
      account: accountStore.find(workspace_raw.account_id)!,
      currency: currencyStore.find(workspace_raw.currency_id)!,
      roles: workspace_raw.roles.map((role: WorkspaceRoleRaw) => convertRawWorkspaceRole(role)),
      // account_roles: workspace_raw.account_roles.map((role: AccountRoleRaw) =>
      //   accountStore.convertRawAccountRole(role),
      // ),
      budgets: workspace_raw.budgets
        .map((budget: BudgetRaw) => budgetStore.convertRawBudget(budget))
        .sort((a: Budget, b: Budget) => {
          return a.id < b.id ? 1 : -1
        })
        .sort((a: Budget, b: Budget) => {
          if (a.effective_daily_budget === b.effective_daily_budget) {
            return 0
          }
          return a.effective_daily_budget < b.effective_daily_budget ? 1 : -1
        })
        .sort((a: Budget, b: Budget) => {
          if (a.metrics_yesterday.roas === b.metrics_yesterday.roas) {
            return 0
          }
          return (a.metrics_yesterday.roas ?? 0) < (b.metrics_yesterday.roas ?? 0) ? 1 : -1
        }),
      // .sort((a: Budget, b: Budget) => {
      //   if (a.metrics_yesterday.roas === b.metrics_yesterday.roas) {
      //     return 0;
      //   }
      //   return (a.metrics_yesterday.roas ?? 0) < (b.metrics_yesterday.roas ?? 0)
      //     ? 1
      //     : -1;
      // }),
    }) as Workspace
  }

  const workspaces = ref<Workspace[] | undefined>(undefined)
  const setWorkspaces = (newWorkspaces: WorkspaceRaw[]) => {
    clearWorkspaces()
    workspaces.value = newWorkspaces
      .map((raw_workspace: WorkspaceRaw) => convertRawWorkspace(raw_workspace))
      .sort((a, b) => {
        if (!a.name || !b.name) {
          return 0
        }
        return a.name.localeCompare(b.name)
      })
    workspaces.value?.forEach((workspace_) => {
      openWebSockets(workspace_)
    })
  }
  const clearWorkspaces = () => {
    workspaces.value?.forEach((workspace_) => {
      closeWebSockets(workspace_)
    })
    workspaces.value = undefined
  }
  const latestWorkspace = async () => {
    if (!workspaces.value || !workspaces.value.length) {
      return undefined
    }
    const last_visited_workspace_id = await Preferences.get({
      key: 'last_visited_workspace_id',
    })
    if (last_visited_workspace_id.value === undefined) {
      return workspaces.value[0]
    }
    for (let i = 0; i < workspaces.value.length; ++i) {
      if (
        last_visited_workspace_id.value === `${workspaces.value[i].id}` &&
        workspaces.value[i].deleted_at === undefined
      ) {
        return workspaces.value[i]
      }
    }
    return workspaces.value[0]
  }
  // const createWorkspace = async (
  //   account_id: number,
  //   name?: string | undefined,
  // ): Promise<Workspace> => {
  //   const data = (await api.post("workspace", {
  //     account_id,
  //     name,
  //   })) as NewWorkspace;
  //   const init = data.init;
  //   const workspace_id = data.workspace_id;
  //   authStore.load(init);
  //   for (let i = 0; i < workspaces.value!.length; ++i) {
  //     if (workspaces.value![i].id === workspace_id) {
  //       return workspaces.value![i];
  //     }
  //   }
  //   throw new Error("New workspace not in the store");
  // };
  // const insertWorkspace = (createWorkspace) => {
  //   for (let i = 0; i < workspaces.value.length; ++i) {
  //     if (workspaces.value[i].id === createWorkspace.id) {
  //       Object.assign(workspaces.value[i], createWorkspace)
  //       workspaces.value[i].touch = workspaces.value[i].touch ? workspaces.value[i].touch + 1 : 1
  //       workspaces.value[i].name = 'test'
  //       return
  //     }
  //   }
  //   workspaces.value.push(createWorkspace)
  // }

  const mergeWorkspaceRaw = (workspace: WorkspaceRaw) => {
    mergeWorkspace(convertRawWorkspace(workspace))
  }
  const mergeWorkspace = (workspace: Workspace) => {
    for (let i = 0; i < workspaces.value!.length; ++i) {
      if (workspaces.value![i].id === workspace.id) {
        Object.assign(workspaces.value![i], workspace)
        return
      }
    }
    workspaces.value!.push(workspace)
  }
  const workspacesInAccounts = computed<WorkspacesInAccount[] | undefined>(() => {
    if (!workspaces.value) {
      return undefined
    }
    const workspacesInAccounts: WorkspacesInAccount[] = []

    workspaces.value.forEach((workspace_: Workspace) => {
      // add to object
      let added_to_existing_account = false
      for (let i = 0; i < workspacesInAccounts.length; ++i) {
        if (workspacesInAccounts[i].account.id === workspace_.account.id) {
          workspacesInAccounts[i].workspaces.push(workspace_)
          added_to_existing_account = true
          break
        }
      }
      if (!added_to_existing_account) {
        workspacesInAccounts.push({
          account: workspace_.account,
          account_name: workspace_.account_name,
          workspaces: [workspace_],
        })
      }
    })
    return (
      workspacesInAccounts
        // sort by name
        .sort((a, b) => {
          if (!a.account_name || !b.account_name) {
            return 0
          }
          return a.account_name.localeCompare(b.account_name)
        })
        // sort by if owner
        .sort((a: WorkspacesInAccount, b: WorkspacesInAccount) => {
          const account_a: Account | undefined = a.account
          const account_b: Account | undefined = b.account
          if (!account_a && account_b) {
            return 1
          } else if (account_a && !account_b) {
            return -1
          } else if (!account_a && !account_b) {
            return 0
          }
          const is_owner_of_a = accountStore.isOwner(authStore.user!.id, account_a!.roles)
          const is_owner_of_b = accountStore.isOwner(authStore.user!.id, account_b!.roles)
          if (!is_owner_of_a && is_owner_of_b) {
            return 1
          } else if (is_owner_of_a && !is_owner_of_b) {
            return -1
          }
          return 0
        })
    )
  })

  const acceptedWorkspacesInAccounts = computed<WorkspacesInAccount[] | undefined>(() => {
    if (!workspacesInAccounts.value) {
      return undefined
    }
    const workspacesInAccounts_: WorkspacesInAccount[] = []

    for (let i = 0; i < workspacesInAccounts.value.length; ++i) {
      let accountAdded: boolean = false
      for (let ii = 0; ii < workspacesInAccounts.value[i].workspaces.length; ++ii) {
        if (isAccepted(workspacesInAccounts.value[i].workspaces[ii])) {
          if (!accountAdded) {
            workspacesInAccounts_.push({
              ...workspacesInAccounts.value[i],
              workspaces: [],
            })
            accountAdded = true
          }
          workspacesInAccounts_[workspacesInAccounts_.length - 1].workspaces.push(
            workspacesInAccounts.value[i].workspaces[ii],
          )
        }
      }
    }
    return workspacesInAccounts_
  })
  const pendingWorkspacesInAccounts = computed<WorkspacesInAccount[] | undefined>(() => {
    if (!workspacesInAccounts.value) {
      return undefined
    }
    const workspacesInAccounts_: WorkspacesInAccount[] = []

    for (let i = 0; i < workspacesInAccounts.value.length; ++i) {
      let accountAdded: boolean = false
      for (let ii = 0; ii < workspacesInAccounts.value[i].workspaces.length; ++ii) {
        if (isPending(workspacesInAccounts.value[i].workspaces[ii])) {
          if (!accountAdded) {
            workspacesInAccounts_.push({
              ...workspacesInAccounts.value[i],
              workspaces: [],
            })
            accountAdded = true
          }
          workspacesInAccounts_[workspacesInAccounts_.length - 1].workspaces.push(
            workspacesInAccounts.value[i].workspaces[ii],
          )
        }
      }
    }
    return workspacesInAccounts_
  })

  const workspaceById = (id: number) => {
    if (!workspaces.value) {
      return undefined
    }
    for (let i = 0; i < workspaces.value.length; ++i) {
      if (workspaces.value[i].id === id) {
        return workspaces.value[i]
      }
    }
  }

  // current workspace based on route
  const workspace = computed<Workspace | undefined>(() => {
    if (!workspaces.value) {
      return undefined
    }
    for (let i = 0; i < workspaces.value.length; ++i) {
      if (parseInt(<string>route.params.workspace) === workspaces.value[i].id) {
        Preferences.set({
          key: 'last_visited_workspace_id',
          value: `${workspaces.value[i].id}`,
        }).then(() => {})
        return workspaces.value[i]
      }
    }
    return undefined
  })

  const workspaceChanged = computed(() => workspace)
  const workspacesLoaded = computed(() => workspaces)

  // workspace

  const mergeBudget = (budget: Budget, workspace: Workspace) => {
    for (let i = 0; i < workspace.budgets.length; ++i) {
      if (workspace.budgets[i].id === budget.id) {
        Object.assign(workspace.budgets[i], budget)
        return
      }
    }
    workspace.budgets.push(budget)
  }

  const mergeRawBudget = (budget_raw: BudgetRaw, workspace: Workspace) => {
    mergeBudget(budgetStore.convertRawBudget(budget_raw), workspace)
  }

  const mergeRawBudgets = (raw_budgets: BudgetRaw[], workspace: Workspace) => {
    // todo: remove redundant workspace

    raw_budgets.forEach((budget_raw: BudgetRaw) => {
      mergeRawBudget(budget_raw, workspace)
    })
  }

  const removeBudget = (budget_id: number, workspace_: Workspace) => {
    api.delete(`budget?id=${budget_id}`)
    for (let i = 0; i < workspace_.budgets.length; ++i) {
      if (workspace_.budgets[i].id === budget_id) {
        workspace_.budgets = workspace_.budgets.filter((budget) => budget.id !== budget_id)
        return
      }
    }
  }
  // ad channels

  const mergeAdChannel = (ad_channel: AdChannel, workspace: Workspace) => {
    for (let i = 0; i < workspace.ad_channels.length; ++i) {
      if (workspace.ad_channels[i].id === ad_channel.id) {
        console.log('updating ad channel', ad_channel)
        Object.assign(workspace.ad_channels[i], ad_channel)
        return
      }
    }
    console.log('creating ad channel', ad_channel)
    workspace.ad_channels.push(ad_channel)
  }

  const mergeAdChannels = (ad_channels: AdChannel[], workspace: Workspace) => {
    // todo: remove redundant ad_channels

    ad_channels.forEach((ad_channel: AdChannel) => {
      mergeAdChannel(ad_channel, workspace)
    })
  }

  const removeAdChannel = (ad_channel_id: number, workspace_: Workspace) => {
    api.delete(`ad-channel?id=${ad_channel_id}`)
    for (let i = 0; i < workspace_.ad_channels.length; ++i) {
      if (workspace_.ad_channels[i].id === ad_channel_id) {
        workspace_.ad_channels = workspace_.ad_channels.filter(
          (ad_channel) => ad_channel.id !== ad_channel_id,
        )
        return
      }
    }
  }

  // websockets

  const openWebSockets = (workspace_: Workspace) => {
    window.Echo.private(`workspace.${workspace_.id}`).listen('.workspace.reload', () => {
      reload(workspace_).then(() => true)
    })
  }
  const closeWebSockets = (workspace_: Workspace) => {
    window.Echo.leave(`workspace.${workspace_.id}`)
  }

  // permissions

  const grant = (permissions: string[], user_id: number, workspace_roles: WorkspaceRole[]) => {
    if (!workspace_roles) {
      return false
    }
    for (let i = 0; i < workspace_roles.length; ++i) {
      if (workspace_roles[i].user.id === user_id && permissions.includes(workspace_roles[i].role)) {
        return true
      }
    }
    return false
  }
  const deny = (permissions: string[], user_id: number, workspace_roles: WorkspaceRole[]) => {
    return !grant(permissions, user_id, workspace_roles)
  }

  // invitations

  const accept = async (workspace_: Workspace) => {
    return new Promise(function (resolve, reject) {
      api
        .put('workspace/invite/accept', {
          workspace_id: workspace_.id,
        })
        .then(() => {
          for (let i = 0; i < workspace_.roles.length; ++i) {
            if (workspace_.roles[i].user.id === authStore.user!.id) {
              workspace_.roles[i].accepted = true
            }
          }
          resolve(undefined)
        })
        .catch((e: unknown) => {
          reject(e)
        })
    })
  }
  const reject = (workspace_: Workspace) => {
    return new Promise(function (resolve, reject) {
      api
        .put('workspace/invite/reject', {
          workspace_id: workspace_.id,
        })
        .then(() => {
          workspaces.value = workspaces.value!.filter(
            (workspace__) => workspace__.id !== workspace_.id,
          )
          resolve(undefined)
        })
        .catch((e: unknown) => {
          reject(e)
        })
    })
  }

  const acceptedCount = computed<number | undefined>(() => {
    if (!workspaces.value || !authStore.user) {
      return undefined
    }
    let count = 0
    if (workspaces.value && authStore.user) {
      workspaces.value.forEach((workspace_) => {
        for (let ii = 0; ii < workspace_.roles.length; ++ii) {
          if (
            workspace_.roles[ii].user.id === authStore.user?.id &&
            workspace_.roles[ii].accepted
          ) {
            count++
            return
          }
        }
      })
    }
    return count
  })
  const invitationCount = computed<number | undefined>(() => {
    if (!workspaces.value || !authStore.user) {
      return undefined
    }
    return workspaces.value.length - acceptedCount.value!
  })
  const hasInvitations = computed<boolean | undefined>(() => {
    if (invitationCount.value === undefined) {
      return undefined
    }
    return invitationCount.value >= 1
  })

  const isAccepted = (workspace_: Workspace): boolean => {
    for (let i = 0; i < workspace_.roles.length; ++i) {
      if (workspace_.roles[i].user.id === authStore.user?.id && workspace_.roles[i].accepted) {
        return true
      }
    }
    return false
  }
  const isPending = (workspace_: Workspace): boolean => {
    let hasRole: boolean = false
    for (let i = 0; i < workspace_.roles.length; ++i) {
      if (workspace_.roles[i].user.id === authStore.user?.id) {
        hasRole = true
        if (workspace_.roles[i].accepted) {
          return false
        }
      }
    }
    return hasRole
  }

  // number badges

  type WorkspaceNotifications = {
    workspace_id: number
    total: number
  }

  const workspacesNotifications = computed<WorkspaceNotifications[] | undefined>(() => {
    if (!workspaces.value) {
      return undefined
    }
    const collection: WorkspaceNotifications[] = []
    for (let i = 0; i < workspaces.value.length; ++i) {
      collection.push({
        workspace_id: workspaces.value[i].id,
        total: 0,
      })
    }
    return collection
  })

  const notificationsForWorkspace = (workspace_id: number): WorkspaceNotifications | undefined => {
    if (!workspacesNotifications.value) {
      return undefined
    }
    for (let i = 0; i < workspacesNotifications.value.length; ++i) {
      if (workspacesNotifications.value[i].workspace_id === workspace_id) {
        return workspacesNotifications.value[i]
      }
    }
    return undefined
  }
  const workspaceNotifications = computed<WorkspaceNotifications | undefined>(() => {
    if (!workspace.value) {
      return undefined
    }
    return notificationsForWorkspace(workspace.value.id)
  })

  const showInvitations = computed<boolean>(() => {
    return (
      invitationCount.value! >= 1 &&
      !['workspaces', 'invites'].includes(route.matched[0].name! as string)
    )
  })
  const menuNotificationCount = computed<number>(() => {
    let count = 0
    if (showInvitations.value) {
      count += invitationCount.value ?? 0
    }
    return count
  })
  const reload = async (workspace: Workspace) => {
    const data: unknown = await api.get(`workspace/reload?workspace_id=${workspace.id}`)
    mergeWorkspaceRaw(data as WorkspaceRaw)
  }
  const budgets_timeframe = ref<BudgetsTimeframe | undefined>(
    window.budgets_timeframe?.length
      ? (window.budgets_timeframe as BudgetsTimeframe)
      : BudgetsTimeframe.PAST_MONTH,
  )
  delete window.budgets_timeframe

  watch(budgets_timeframe, () => {
    if (budgets_timeframe.value) {
      Preferences.set({
        key: 'budgets_timeframe',
        value: `${budgets_timeframe.value}`,
      }).then(() => {})
    } else {
      Preferences.remove({ key: 'budgets_timeframe' }).then(() => {})
    }
  })

  const setBudgetsTimeframe = (budgets_timeframe_: BudgetsTimeframe) => {
    budgets_timeframe.value = budgets_timeframe_
  }

  const find = (workspace_id: number): Workspace | undefined => {
    return workspaces
      .value!.filter((workspace) => workspace.deleted_at === undefined)
      .find((workspace: Workspace) => workspace.id === workspace_id)
  }

  return {
    find,
    budgets_timeframe,
    setBudgetsTimeframe,

    workspaces,
    workspacesLoaded,
    workspacesInAccounts,
    acceptedWorkspacesInAccounts,
    pendingWorkspacesInAccounts,
    latestWorkspace,
    setWorkspaces,
    clearWorkspaces,
    // insertWorkspace,
    workspace,
    mergeWorkspace,
    mergeWorkspaceRaw,
    workspaceChanged,
    workspaceById,
    reload,
    // createWorkspace,

    convertRawWorkspace,
    convertRawWorkspaceRole,

    showInvitations,
    menuNotificationCount,

    // workspace
    mergeBudget,
    mergeRawBudget,
    mergeRawBudgets,
    removeBudget,

    // ad_channels
    mergeAdChannel,
    mergeAdChannels,
    removeAdChannel,

    // permissions
    grant,
    deny,

    // invitations
    accept,
    reject,
    acceptedCount,
    invitationCount,
    hasInvitations,
    isAccepted,
    isPending,

    // number badges
    notificationsForWorkspace,
    workspaceNotifications,
  }
})
