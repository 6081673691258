import { defineStore } from 'pinia'
import { ref } from 'vue'

import { nullsToUndefined } from '@/helpers/nulls-to-undefined.ts'
import type { CurrencyRate } from '@/models/currency-rate.ts'

export const useCurrencyRateStore = defineStore('currency-rate', () => {
  const currency_rates = ref<CurrencyRate[] | undefined>(undefined)

  const load = (currency_rates_: CurrencyRate[]) => {
    currency_rates.value = nullsToUndefined(currency_rates_)
  }
  const clear = () => {
    currency_rates.value = undefined
  }

  const between = (from_currency_id: number, to_currency_id: number): number | undefined => {
    if (from_currency_id === to_currency_id) {
      return 1.0
    }
    return currency_rates.value!.find(
      (currency_rate: CurrencyRate) =>
        currency_rate.from_currency_id === from_currency_id &&
        currency_rate.to_currency_id === to_currency_id,
    )?.value
  }

  return {
    currency_rates,
    load,
    clear,
    between,
  }
})
