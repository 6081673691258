<script setup lang="ts">
import { useI18n } from 'vue-i18n'

import budgets from '@/assets/budgets.png'
import metrics from '@/assets/metrics.png'
import workspaces from '@/assets/workspaces.png'
import Feature from '@/ui/components/landing/feature.vue'

const { t } = useI18n()
</script>
<template>
  <div class="container flex flex-col space-y-20 lg:space-y-40" id="features">
    <template v-for="i in 5">
      <feature
        :key="i"
        v-if="i === 1"
        :image-position="i % 2 === 1 ? `left` : `right`"
        :title="t('landing.features.cross-channel-metrics.title')"
      >
        <template #description>
          <div class="text-xl leading-relaxed">
            {{ t('landing.features.cross-channel-metrics.description') }}
          </div>
        </template>
        <template #image>
          <div class="budget card">
            <img
              :src="metrics"
              class="aspect-[659/473] w-full rounded-sm object-cover object-top"
              :alt="t('landing.features.learning-phases.title')"
            />
          </div>
        </template>
      </feature>
      <feature
        :key="i"
        v-if="i === 2"
        :image-position="i % 2 === 1 ? `left` : `right`"
        :title="t('landing.features.budget-management.title')"
      >
        <template #description>
          <div class="text-xl leading-relaxed">
            {{ t('landing.features.budget-management.description') }}
          </div>
        </template>
        <template #image>
          <div class="budget card">
            <img
              :src="budgets"
              class="aspect-[659/473] w-full rounded-sm object-cover object-top"
              :alt="t('landing.features.budget-management.title')"
            />
          </div>
        </template>
      </feature>
      <feature
        :key="i"
        v-if="i === 3"
        :image-position="i % 2 === 1 ? `left` : `right`"
        :title="t('landing.features.workspaces.title')"
      >
        <template #description>
          <div class="text-xl leading-relaxed">
            {{ t('landing.features.workspaces.description') }}
          </div>
        </template>
        <template #image>
          <div class="budget card">
            <img
              :src="workspaces"
              class="aspect-[659/473] w-full rounded-sm object-cover object-top"
              :alt="t('landing.features.workspaces.title')"
            />
          </div>
        </template>
      </feature>
    </template>
  </div>
</template>
