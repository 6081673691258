export const waitFor = <T>(checkFunction: () => T | undefined, interval = 100): Promise<T> => {
  return new Promise((resolve) => {
    const checkValue = () => {
      const value = checkFunction()
      if (value !== undefined) {
        resolve(value)
      } else {
        setTimeout(checkValue, interval)
      }
    }
    checkValue()
  })
}
