<script setup lang="ts">
import { CaretDownIcon } from '@radix-icons/vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
<template>
  <section
    class="container relative flex min-h-screen w-full flex-col items-center justify-center overflow-hidden text-center"
  >
    <router-link
      :to="{ name: 'home', hash: '#features' }"
      class="absolute bottom-0 select-none p-10"
    >
      <CaretDownIcon class="h-10 w-auto animate-bounce"></CaretDownIcon>
    </router-link>
    <span
      class="relative z-[2] mx-auto mt-9 block w-full max-w-6xl text-balance font-sans text-5xl font-medium leading-tight sm:text-center sm:text-7xl sm:leading-tight xl:text-[90px] xl:leading-snug"
    >
      <span> {{ t('landing.splash.title') }}{{ ' ' }}</span>
      <span class="relative z-[5] inline-block font-semibold">
        <span>
          {{ t('landing.splash.title-highlighted') }}
        </span>
        <span
          class="absolute bottom-[7px] left-[3px] z-[-1] inline-block h-[4px] w-[calc(100%-5px)] rounded-sm bg-gradient-to-r from-[#22fbdc] to-[#9f69fb] sm:bottom-[6px] sm:h-[6px]"
        ></span>
      </span>
    </span>
    <!--    <span-->
    <!--      class="z-[2] mx-auto mt-[36px] block w-full max-w-4xl text-balance font-mono text-2xl leading-relaxed sm:mt-[46px] sm:text-center sm:leading-loose md:leading-loose xl:leading-loose"-->
    <!--      >{{ t("landing.splash.description") }}-->
    <!--    </span>-->
    <div class="z-[2] mt-[46px] hidden space-x-8 md:mt-[62px] md:flex md:justify-center">
      <!--      <router-link-->
      <!--        :to="{ name: 'home', hash: '#features' }"-->
      <!--        class="extra large muted button rounded-sm font-mono"-->
      <!--      >-->
      <!--        <span>How it works</span>-->
      <!--      </router-link>-->
    </div>
    <!--    <div-->
    <!--      class="mx-auto mt-16 aspect-[16/9] w-full rounded-xl bg-white dark:bg-black/30 ring-[10px] ring-zinc-50 lg:mt-32 lg:max-w-7xl"-->
    <!--    ></div>-->
    <!--    <img-->
    <!--      class="absolute inset-0 z-[1] w-full object-cover object-top opacity-20"-->
    <!--      :src="gradient"-->
    <!--      alt=""-->
    <!--    />-->
  </section>
</template>
