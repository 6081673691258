<script setup lang="ts">
import type { PropType } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import ExternalLink from '@/ui/components/docs/external-link.vue'

const props = defineProps({
  route: {
    type: Object as PropType<RouteLocationRaw>,
    required: true,
  },
})
</script>
<template>
  <p>
    <span>Learn more about </span><external-link :route="props.route"><slot></slot></external-link>
  </p>
</template>
