<script setup lang="ts">
const props = defineProps({
  landing: {
    type: Boolean,
    required: false,
    default: false,
  },
})
</script>
<template>
  <div
    :class="`mt-1.5 grid grid-cols-1 gap-7 px-5 sm:px-8 lg:grid-cols-2 ${!props.landing ? `2xl:grid-cols-3` : ``}`"
  >
    <slot></slot>
  </div>
</template>
