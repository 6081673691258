<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'
import LearnMoreAbout from '@/ui/components/docs/learn-more-about.vue'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      Accounts in Charter are organised using workspaces. Workspaces are typically used to separate
      clients if you’re a marketing agency, or if you’re a business you can use them to separate
      brands.
    </p>
    <p>
      <span class="inline font-bold">Note:</span> if you’re wanting to have two separate billing set
      ups, you’ll need to do that using two separate accounts.
    </p>
    <learn-more-about
      :route="{
        name: 'docs-billing',
      }"
      >Billing</learn-more-about
    >
  </div>
</template>
