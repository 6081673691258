<script setup lang="ts">
import type { PropType } from 'vue'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import DialogBody from '@/ui/components/dialog-body.vue'
import DialogTitlebar from '@/ui/components/dialog-titlebar.vue'
import StyledButton from '@/ui/components/form/styled-button.vue'
import { ButtonType } from '@/enums/button-type.ts'
import { api } from '@/helpers/api.ts'
import { waitFor } from '@/helpers/wait-for.ts'
import type { AdChannel } from '@/models/ad-channel.ts'
import type { MetaAdAccountOption } from '@/models/meta-ad-account-option.ts'
import type { Workspace } from '@/models/workspace.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const emit = defineEmits<{
  (e: 'complete'): void
  (e: 'cancel'): void
}>()
const props = defineProps({
  accessToken: {
    type: String,
    required: true,
  },
  adChannels: {
    type: Array as PropType<MetaAdAccountOption[]>,
    required: true,
  },
})
const workspaceStore = useWorkspaceStore()
const router = useRouter()
const route = useRoute()

const pending = ref<boolean>(false)
const errors = ref<FormErrors>({})

type FormErrors = {
  ad_channel?: string
}
const selected_ad_channel = ref<MetaAdAccountOption | undefined>(undefined)

const save = async () => {
  if (pending.value) {
    return
  }
  pending.value = true

  if (props.adChannels.length === 1) {
    selected_ad_channel.value = props.adChannels[0]
  }

  if (selected_ad_channel.value === undefined) {
    errors.value.ad_channel = 'Please select an ad channel'
    pending.value = false
    return
  }
  api
    .put(`ad-channel/meta-confirm`, {
      workspace_id: parseInt(route.params.workspace as string),
      access_token: props.accessToken,
      ad_channel_id: selected_ad_channel.value.id,
    })
    .then(async (ad_channel: unknown) => {
      await waitFor(() => workspaceStore.workspaces !== undefined)
      const workspace = workspaceStore.workspaces!.find(
        (workspace: Workspace) => workspace.id === parseInt(route.params.workspace as string),
      )!
      const first_ad_channel = workspace.ad_channels.length === 0

      workspaceStore.mergeAdChannel(ad_channel as AdChannel, workspace)

      await router.replace({
        name: first_ad_channel ? 'budgets' : 'ad-channels',
        params: {
          workspace: parseInt(route.params.workspace as string),
        },
        // query: {
        //   setup: context === "setup" ? "true" : undefined,
        //   done: "true",
        // },
      })
      emit('complete')

      //
      //
      //
      //
      // if (!workspaceStore.workspace) {
      //   await waitFor(() => workspaceStore.workspace);
      // }
      // let adChannelExists = false;
      // if (workspaceStore.workspace) {
      //   adChannelExists = workspaceStore.workspace.ad_channels.some(
      //     (ad_channel: AdChannel) =>
      //       ad_channel.id === (ad_channel_raw as AdChannel).id,
      //   );
      // }
      // // if (!adChannelExists) {
      // //   adChannelImportingToast.value = true;
      // // }
      // workspaceStore.mergeAdChannel(
      //   data as AdChannel,
      //   workspaceStore.workspace!,
      // );
      // emit("complete");
    })
}
// const adChannelImportingToast = ref(false);
</script>
<template>
  <div class="dialog-box">
    <dialog-titlebar @close="emit('cancel')" title="Select ad channel"></dialog-titlebar>
    <dialog-body>
      <div class="flex w-[400px] flex-col space-y-5">
        <select class="w-full" v-model="selected_ad_channel">
          <option :value="ad_channel" v-for="ad_channel in props.adChannels" :key="ad_channel.id">
            {{ ad_channel.name }}
          </option>
        </select>
        <div class="flex justify-start pl-0.5">
          <styled-button :type="ButtonType.PRIMARY" :submitting="pending" @click="save"
            >Continue</styled-button
          >
        </div>
      </div>
    </dialog-body>
    <!--    <toast-->
    <!--      v-models="adChannelImportingToast"-->
    <!--      :variant="ToastVariant.SUCCESS"-->
    <!--      title="Ad channel connected"-->
    <!--      description="Your ad channel has been successfully connected and your workspace are now being imported."-->
    <!--      short-description="Your workspace are not being imported."-->
    <!--    ></toast>-->
  </div>
</template>
