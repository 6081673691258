<script setup lang="ts">
import { useRoute } from 'vue-router'

import SubNavigation from '@/ui/components/sub-navigation.vue'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const route = useRoute()
const workspaceStore = useWorkspaceStore()

// const has_ad_channels = computed<boolean | undefined>(() => {
//   if (!workspaceStore.workspace) {
//     return undefined;
//   }
//   return workspaceStore.workspace.ad_channels.length >= 1;
// });
// const has_storefronts = computed<boolean | undefined>(() => {
//   if (!workspaceStore.workspace) {
//     return undefined;
//   }
//   return workspaceStore.workspace.storefronts.length >= 1;
// });
</script>
<template>
  <sub-navigation>
    <div class="flex items-center space-x-8" v-if="workspaceStore.workspace">
      <router-link
        :to="{
          name: 'budgets',
          params: { workspace: workspaceStore.workspace.id },
          query: { setup: route.query.setup },
        }"
        :class="`select-none transition-all ${
          route.matched[1]?.name === 'budgets' ? `` : `text-white/30 hover:text-white`
        }`"
        >Budgets</router-link
      >
      <!--      <router-link-->
      <!--        v-if="has_ad_channels"-->
      <!--        :to="{-->
      <!--          name: 'storefronts',-->
      <!--          params: { workspace: workspaceStore.workspace.id },-->
      <!--        }"-->
      <!--        :class="`select-none transition-all ${-->
      <!--          route.matched[1]?.name === 'storefronts'-->
      <!--            ? ``-->
      <!--            : `text-zinc-300 hover:text-black dark:text-white/30 dark:hover:text-white`-->
      <!--        }`"-->
      <!--        >Storefronts</router-link-->
      <!--      >-->
      <router-link
        :to="{
          name: 'ad-channels',
          params: { workspace: workspaceStore.workspace.id },
        }"
        :class="`select-none transition-all ${
          route.matched[1]?.name === 'ad-channels' ? `` : `text-white/30 hover:text-white`
        }`"
        >Ad Channels</router-link
      >
    </div>
    <slot></slot>
  </sub-navigation>
</template>
