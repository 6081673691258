import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { AccountRoles } from '@/enums/account-roles.ts'
import { api } from '@/helpers/api.ts'
import { nullsToUndefined } from '@/helpers/nulls-to-undefined.ts'
import type { Account, AccountRaw } from '@/models/account.ts'
import type { AccountRole, AccountRoleRaw } from '@/models/account-role.ts'
import { useAuthStore } from '@/stores/auth.ts'
import { useCountryStore } from '@/stores/country.ts'
import { useCurrencyStore } from '@/stores/currency.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

export const useAccountStore = defineStore('account', () => {
  const route = useRoute()
  const authStore = useAuthStore()
  const currencyStore = useCurrencyStore()
  const countryStore = useCountryStore()
  const workspaceStore = useWorkspaceStore()

  const convertRawAccountRole = (account_role_raw: AccountRoleRaw): AccountRole => {
    return nullsToUndefined({
      ...account_role_raw,
      user: authStore.convertRawUser(account_role_raw.user),
      inviter: account_role_raw.inviter
        ? authStore.convertRawUser(account_role_raw.inviter)
        : undefined,
    }) as AccountRole
  }

  const convertRawAccount = (account_raw: AccountRaw): Account => {
    return nullsToUndefined({
      ...account_raw,
      currency: currencyStore.find(account_raw.currency_id),
      country: countryStore.find(account_raw.country_id),
      roles: account_raw.roles.map((role: AccountRoleRaw) => convertRawAccountRole(role)),
    }) as Account
  }

  const accounts = ref<Account[] | undefined>(undefined)
  const setAccounts = (newAccounts: AccountRaw[]) => {
    clearAccounts()
    accounts.value = newAccounts.map((account_) => convertRawAccount(account_))
    accounts.value?.forEach((account_) => {
      openWebSockets(account_)
    })
  }
  const clearAccounts = () => {
    accounts.value?.forEach((account_) => {
      closeWebSockets(account_)
    })
    accounts.value = undefined
  }
  const mergeAccount = (account_: Account) => {
    if (!accounts.value) {
      accounts.value = [account_]
      return
    }
    for (let i = 0; i < accounts.value.length; ++i) {
      if (account_.id === accounts.value[i].id) {
        Object.assign(accounts.value[i], account_)
        return
      }
    }
    accounts.value.push(account_)
  }
  const account = computed<Account | undefined>(() => {
    if (!accounts.value) {
      return undefined
    }
    for (let i = 0; i < accounts.value.length; ++i) {
      if (parseInt(<string>route.params.account) === accounts.value[i].id) {
        return accounts.value[i]
      }
    }
    if (workspaceStore.workspace) {
      for (let i = 0; i < accounts.value.length; ++i) {
        if (workspaceStore.workspace.account.id === accounts.value[i].id) {
          return accounts.value[i]
        }
      }
    }
    return undefined
  })
  // const removeProductById = (id: number) => {
  //   if (!account.value) {
  //     return;
  //   }
  //   for (let i = 0; i < account.value.workspaces.length; ++i) {
  //     for (let ii = 0; ii < account.value.workspaces[i].products.length; ++ii) {
  //       if (account.value.workspaces[i].products[ii].product_id === id) {
  //         account.value.workspaces[i].products.splice(ii, 1);
  //       }
  //     }
  //   }
  // };
  // const products = computed(() => {
  //   if (!account.value) {
  //     return [];
  //   }
  //   const products_: WorkspaceProduct[] = [];
  //   for (let i = 0; i < account.value.workspaces.length; ++i) {
  //     for (let ii = 0; ii < account.value.workspaces[i].products.length; ++ii) {
  //       let item: WorkspaceProduct | undefined = undefined;
  //       for (let iii = 0; iii < products_.length; ++iii) {
  //         if (
  //           products_[iii].product.id ===
  //           account.value.workspaces[i].products[ii].product_id
  //         ) {
  //           item = products_[iii];
  //           break;
  //         }
  //       }
  //       if (!item) {
  //         item = {
  //           id: account.value.workspaces[i].products[ii].product.id,
  //           name: account.value.workspaces[i].products[ii].product.name,
  //           product: account.value.workspaces[i].products[ii].product,
  //           workspaces: [account.value.workspaces[i]],
  //           values: [account.value.workspaces[i].products[ii].value],
  //         } as Workspa;
  //         products_.push(item);
  //       } else {
  //         item.workspaces.push(account.value.workspaces[i]);
  //         item.values.push(account.value.workspaces[i].products[ii].value);
  //       }
  //     }
  //   }
  //   return products_.sort((a, b) => a.id - b.id);
  // });

  // permissions

  const grant = (permissions: string[], user_id: number, roles: AccountRole[]) => {
    if (!roles) {
      return false
    }
    for (let i = 0; i < roles.length; ++i) {
      if (roles[i].user.id === user_id && permissions.includes(roles[i].role)) {
        return true
      }
    }
    return false
  }
  const deny = (permissions: string[], user_id: number, roles: AccountRole[]) => {
    return !grant(permissions, user_id, roles)
  }
  const isOwner = (user_id: number, account_roles: AccountRole[]) => {
    return grant([AccountRoles.OWNER], user_id, account_roles)
  }

  // invitations

  const accept = async (account_: Account) => {
    return new Promise(function (resolve, reject) {
      api
        .put('account/invite/accept', {
          account_id: account_.id,
        })
        .then(() => {
          for (let i = 0; i < account_.roles.length; ++i) {
            if (account_.roles[i].user.id === authStore.user!.id) {
              account_.roles[i].accepted = true
            }
          }
          resolve(undefined)
        })
        .catch((e: unknown) => {
          reject(e)
        })
    })
  }
  const reject = (account_: Account) => {
    return new Promise(function (resolve, reject) {
      api
        .put('account/invite/reject', {
          account_id: account_.id,
        })
        .then(() => {
          accounts.value = accounts.value!.filter((account__) => account__.id !== account_.id)
          resolve(undefined)
        })
        .catch((e: unknown) => {
          reject(e)
        })
    })
  }

  const acceptedCount = computed<number | undefined>(() => {
    if (!accounts.value || !authStore.user) {
      return undefined
    }
    let count = 0
    if (accounts.value && authStore.user) {
      accounts.value.forEach((account) => {
        for (let ii = 0; ii < account.roles.length; ++ii) {
          if (account.roles[ii].user.id === authStore.user?.id && account.roles[ii].accepted) {
            count++
            return
          }
        }
      })
    }
    return count
  })
  const invitationCount = computed<number | undefined>(() => {
    if (!accounts.value || !authStore.user) {
      return undefined
    }
    return accounts.value.length - acceptedCount.value!
  })
  const hasInvitations = computed<boolean | undefined>(() => {
    if (invitationCount.value === undefined) {
      return undefined
    }
    return invitationCount.value >= 1
  })

  const find = (account_id: number): Account | undefined => {
    return accounts.value?.find((account: Account) => account.id === account_id)
  }

  const reload = async (account: Account) => {
    const data: unknown = await api.get(`account/reload?account_id=${account.id}`)
    mergeAccount(convertRawAccount(data as AccountRaw))
  }
  // websockets

  const openWebSockets = (account_: Account) => {
    window.Echo.private(`account.${account_.id}`).listen('.account.reload', () => {
      reload(account_).then(() => true)
    })
  }
  const closeWebSockets = (account_: Account) => {
    window.Echo.leave(`account.${account_.id}`)
  }

  return {
    accounts,
    account,
    find,
    // products,
    setAccounts,
    clearAccounts,
    // removeProductById,
    mergeAccount,

    convertRawAccount,
    convertRawAccountRole,

    // permissions
    grant,
    deny,
    isOwner,

    // invitations
    accept,
    reject,
    acceptedCount,
    invitationCount,
    hasInvitations,
  }
})
