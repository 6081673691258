import { Capacitor } from '@capacitor/core'
import { createRouter, createWebHistory } from 'vue-router'

import DocsBillingPage from '@/ui/components/docs/account-management/billing.vue'
import DocsTeamAccessPage from '@/ui/components/docs/account-management/team-access.vue'
import DocsWorkspacesPage from '@/ui/components/docs/account-management/workspaces.vue'
import DocsBudgetChangeApprovalPage from '@/ui/components/docs/core-concepts/budget-change-approval.vue'
import DocsBudgetGroupsPage from '@/ui/components/docs/core-concepts/budget-groups.vue'
import DocsBudgetThresholdsPage from '@/ui/components/docs/core-concepts/budget-thresholds.vue'
import DocsTargetMetricsPage from '@/ui/components/docs/core-concepts/target-metrics.vue'
import DocsIntroductionPage from '@/ui/components/docs/introduction/introduction.vue'
import DocsAssigningBudgetsPage from '@/ui/components/docs/onboarding/assigning-budgets.vue'
import DocsBudgetDashboardPage from '@/ui/components/docs/onboarding/budget-dashboard.vue'
import DocsConnectingAdChannelsPage from '@/ui/components/docs/onboarding/connecting-ad-channels.vue'
import DocsCreatingAnAccountPage from '@/ui/components/docs/onboarding/creating-an-account.vue'
import DocsCreatingBudgetGroupsPage from '@/ui/components/docs/onboarding/creating-budget-groups.vue'
import Error404 from '@/ui/components/error/404.vue'
import RoadmapPage from '@/ui/components/landing/roadmap/page.vue'
import WorkspaceLayout from '@/ui/layouts/workspace.vue'
import SignInPage from '@/ui/pages/auth/sign-in.vue'
import SignInEmailPage from '@/ui/pages/auth/sign-in-with-email.vue'
import SignUpPage from '@/ui/pages/auth/sign-up.vue'
import SignUpEmailPage from '@/ui/pages/auth/sign-up-with-email.vue'
import DocsLayout from '@/ui/pages/docs.vue'
import MarketingPage from '@/ui/pages/index.vue'
import TermsPage from '@/ui/pages/legal/accept-terms.vue'
import PrivacyPolicyPage from '@/ui/pages/legal/privacy-policy.vue'
import TermsAndConditionsPage from '@/ui/pages/legal/terms-of-service.vue'
import OAuthGoogleAdChannel from '@/ui/pages/oauth/google-ad-channel.vue'
import OAuthGoogle from '@/ui/pages/oauth/google-auth.vue'
import OAuthMetaAdChannel from '@/ui/pages/oauth/meta-ad-channel.vue'
import OAuthMeta from '@/ui/pages/oauth/meta-auth.vue'
import AdChannelsPage from '@/ui/pages/workspace/ad-channel.vue'
import BudgetsPage from '@/ui/pages/workspace/budgets.vue'
import WorkspacesPage from '@/ui/pages/workspaces.vue'
import { useAuthStore } from '@/stores/auth.ts'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: MarketingPage,
      meta: { tidio: true },
      children: [
        {
          path: '/privacy-policy',
          name: 'privacy',
          component: PrivacyPolicyPage,
        },
        {
          path: '/terms-of-service',
          name: 'terms-of-service',
          component: TermsAndConditionsPage,
        },
        {
          path: '/road-map',
          name: 'roadmap',
          component: RoadmapPage,
        },
      ],
    },
    {
      path: '/docs',
      name: 'docs',
      component: DocsLayout,
      meta: { tidio: false },
      children: [
        {
          path: 'introduction',
          name: 'docs-introduction',
          component: DocsIntroductionPage,
        },
        {
          path: 'budget-groups',
          name: 'docs-budget-groups',
          component: DocsBudgetGroupsPage,
        },
        {
          path: 'target-metrics',
          name: 'docs-target-metrics',
          component: DocsTargetMetricsPage,
        },
        {
          path: 'budget-thresholds',
          name: 'docs-budget-thresholds',
          component: DocsBudgetThresholdsPage,
        },
        {
          path: 'budget-change-approval',
          name: 'docs-budget-change-approval',
          component: DocsBudgetChangeApprovalPage,
        },
        {
          path: 'budget-dashboard',
          name: 'docs-budget-dashboard',
          component: DocsBudgetDashboardPage,
        },
        {
          path: 'creating-an-account',
          name: 'docs-creating-an-account',
          component: DocsCreatingAnAccountPage,
        },
        {
          path: 'connecting-ad-channels',
          name: 'docs-connecting-ad-channels',
          component: DocsConnectingAdChannelsPage,
        },
        {
          path: 'creating-budget-groups',
          name: 'docs-creating-budget-groups',
          component: DocsCreatingBudgetGroupsPage,
        },
        {
          path: 'assigning-workspace',
          name: 'docs-assigning-workspace',
          component: DocsAssigningBudgetsPage,
        },
        {
          path: 'workspaces',
          name: 'docs-workspaces',
          component: DocsWorkspacesPage,
        },
        {
          path: 'team-access',
          name: 'docs-team-access',
          component: DocsTeamAccessPage,
        },
        {
          path: 'billing',
          name: 'docs-billing',
          component: DocsBillingPage,
        },
      ],
    },
    {
      path: '/oauth',
      children: [
        {
          path: 'meta',
          name: 'oauth-meta',
          component: OAuthMeta,
        },
        {
          path: 'google',
          name: 'oauth-google',
          component: OAuthGoogle,
        },
        {
          path: 'meta-ad-channel',
          name: 'oauth-meta-ad-channel',
          component: OAuthMetaAdChannel,
          meta: { title: 'Meta Ad Channel', authenticated: true },
        },
        {
          path: 'google-ad-channel',
          name: 'oauth-google-ad-channel',
          component: OAuthGoogleAdChannel,
          meta: { title: 'Google Channel', authenticated: true },
        },
      ],
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: SignInPage,
      meta: { title: 'Sign in', unauthenticated: true },
    },
    {
      path: '/sign-in/email',
      name: 'sign-in-with-email',
      component: SignInEmailPage,
      meta: { title: 'Sign in', unauthenticated: true },
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: SignUpPage,
      meta: { title: 'Sign up', unauthenticated: true },
    },
    {
      path: '/sign-up/email',
      name: 'sign-up-with-email',
      component: SignUpEmailPage,
      meta: { title: 'Sign up', unauthenticated: true },
    },
    {
      path: '/workspaces',
      name: 'workspaces',
      component: WorkspacesPage,
      meta: { title: 'Workspaces', authenticated: true },
    },
    {
      path: '/terms',
      name: 'terms',
      component: TermsPage,
      meta: { title: 'Terms', authenticated: true },
    },
    {
      path: '/:workspace(\\d+)',
      name: 'workspace',
      component: WorkspaceLayout,
      meta: { authenticated: true, tidio: true },
      redirect: (route) => {
        return {
          name: 'budgets',
          params: { workspace: route.params.workspace },
        }
      },
      children: [
        {
          path: 'budgets',
          name: 'budgets',
          component: BudgetsPage,
          meta: { title: 'Budgets' },
        },
        {
          path: 'ad-channels',
          name: 'ad-channels',
          component: AdChannelsPage,
          meta: { title: 'Ad channels' },
        },
      ],
    },
    {
      path: '/:notFound(.*)',
      component: Error404,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    from.meta.x = 1 // todo: remove this, its just for typescript
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 180,
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})
router.beforeEach((to, from) => {
  const authStore = useAuthStore()

  // redirect authenticated home to workspaces for apps
  if (Capacitor.isNativePlatform() && to.name === 'home' && authStore.token) {
    return {
      name: 'workspaces',
    }
  }

  // redirect unauthenticated pages to dashboard if authenticated
  if (to.meta.unauthenticated && authStore.token) {
    return {
      name: 'workspaces',
    }
  }

  // redirect authenticated pages to sign in if unauthenticated
  if (to.meta.authenticated && !authStore.token) {
    return {
      name: 'sign-in',
      query: {
        to: btoa(JSON.stringify(to.fullPath)),
      },
    }
  }

  // // redirect workspace selector page to default workspace or invite page if appropriate.
  // // if not initialised yet, continue to the workspaces page, it can redirect itself once initialisation is complete
  // if (to.name === "workspaces" && authStore.user && workspaceStore.workspaces) {
  //   const redirect = workspacesRedirect(
  //     authStore.user.id,
  //     workspaceStore.workspaces,
  //   );
  //
  //   if (redirect) {
  //     return redirect;
  //   }
  // }
  // title
  // document.title = to.meta.title
  //   ? `${to.meta.title as string} - AI-powered ad budgeting`
  //   : "Charter - AI-powered ad budgeting";

  // legal
  if (to.matched[0].name === 'home') {
    document.body.classList.add('landing')
  } else {
    document.body.classList.remove('landing')
  }
  // tidio
  if (to.meta.tidio === true) {
    document.body.classList.add('tidio')
  } else {
    document.body.classList.remove('tidio')
  }

  window.dispatchEvent(new CustomEvent('navigating', { detail: { to, from } }))
})
router.afterEach((to, from) => {
  window.dispatchEvent(new CustomEvent('navigated', { detail: { to, from } }))
})
export default router

// export workspacesRedirect = (
//   user_id: number,
//   workspaces: Workspace[],
// ) => {
//   // const workspaceStore = useWorkspaceStore();
//   //
//   // if (workspaceStore.acceptedCount! === 1) {
//   //
//   //   return {
//   //     name: "workspace",
//   //     params: {
//   //       workspace: workspaces[0].id,
//   //     },
//   //   };
//   // } else if (
//   //   workspaceStore.acceptedCount! === 0 &&
//   //   workspaceStore.invitationCount! === 1
//   // ) {
//   //   return {
//   //     name: "invite",
//   //     params: {
//   //       workspace: workspaces[0].id,
//   //     },
//   //   };
//   // } else if (
//   //   workspaceStore.acceptedCount! === 0 &&
//   //   workspaceStore.invitationCount! >= 2
//   // ) {
//   //   return {
//   //     name: "invites",
//   //   };
//   // } else if (
//   //   workspaceStore.acceptedCount! === 0 &&
//   //   workspaceStore.invitationCount! === 0
//   // ) {
//   //   // todo: special screen for this - option to create a new workspace
//   // }
// };
