<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

import MobileCloseIcon from '@/ui/components/mobile-close-icon.vue'
import MobileMenuIcon from '@/ui/components/mobile-menu-icon.vue'
import NumberBadge from '@/ui/components/number-badge.vue'
import { useAuthStore } from '@/stores/auth.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const workspaceStore = useWorkspaceStore()
const authStore = useAuthStore()

const route = useRoute()
const open = ref<boolean>(false)

watch(open, (after, before) => {
  if (!before && after) {
    window.dispatchEvent(new CustomEvent('mobile-menu-open'))
  } else if (before && !after) {
    window.dispatchEvent(new CustomEvent('mobile-menu-close'))
  }
})
</script>
<template>
  <div>
    <div
      class="group relative cursor-pointer rounded-sm p-6"
      @click="open = true"
      id="mobile-menu-trigger"
    >
      <mobile-menu-icon class="transition-all text-white"></mobile-menu-icon>
      <div class="absolute right-3 top-3" v-if="workspaceStore.menuNotificationCount >= 1">
        <number-badge :n="workspaceStore.menuNotificationCount"></number-badge>
      </div>
    </div>
    <div
      :class="`fixed inset-0 z-50 flex flex-col transition-all duration-75 bg-black/30 ${open ? `opacity-100` : `pointer-events-none opacity-0`}`"
    >
      <div class="group cursor-pointer rounded-sm p-6" @click="open = false">
        <mobile-close-icon class="transition-all text-white"></mobile-close-icon>
      </div>
      <div class="flex grow items-center justify-center">
        <div class="flex flex-col items-center space-y-3">
          <router-link
            @click="open = false"
            :to="{
              name: 'budgets',
              params: { workspace: route.params.workspace },
            }"
            class="rounded-sm px-12 py-4 text-3xl hover:bg-zinc-100"
            >Budgets</router-link
          >
          <router-link
            @click="open = false"
            v-if="workspaceStore.showInvitations"
            :to="{ name: 'invites' }"
            class="rounded-sm px-12 py-4 text-3xl hover:bg-zinc-100"
            ><span>Invitations</span>
            <number-badge :n="workspaceStore.invitationCount"></number-badge
          ></router-link>
          <router-link
            @click="open = false"
            v-if="
              workspaceStore.acceptedCount! >= 2 &&
              !['workspaces', 'invites'].includes(route.matched[0].name! as string)
            "
            :to="{ name: 'workspaces' }"
            class="rounded-sm px-12 py-4 text-3xl hover:bg-zinc-100"
            ><span>Workspaces</span>
          </router-link>
          <span
            @click="
              () => {
                authStore.signOut()
                open = false
              }
            "
            class="cursor-pointer rounded-sm px-12 py-4 text-3xl hover:bg-zinc-100"
            >Sign out</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
