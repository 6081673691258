import { defineStore } from 'pinia'
import { ref } from 'vue'

import { CurrencySymbolPosition } from '@/enums/currency-symbol-position.ts'
import { formatNumber } from '@/helpers/formatting.ts'
import { nullsToUndefined } from '@/helpers/nulls-to-undefined.ts'
import type { Currency } from '@/models/currency.ts'
import { useCurrencyRateStore } from '@/stores/currency-rates.ts'

export const useCurrencyStore = defineStore('currency', () => {
  const currencyRateStore = useCurrencyRateStore()

  const currencies = ref<Currency[] | undefined>(undefined)

  const load = (currencies_: Currency[]) => {
    currencies.value = nullsToUndefined(
      currencies_
        .sort((a, b) => {
          // sort by name
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        .sort((a, b) => {
          // sort GBP, USD and EUR to the top
          if (a.code === 'EUR') {
            return -1
          }
          if (b.code === 'EUR') {
            return 1
          }
          return 0
        })
        .sort((a, b) => {
          // sort GBP, USD and EUR to the top
          if (a.code === 'GBP') {
            return -1
          }
          if (b.code === 'GBP') {
            return 1
          }
          return 0
        })
        .sort((a, b) => {
          // sort GBP, USD and EUR to the top
          if (a.code === 'USD') {
            return -1
          }
          if (b.code === 'USD') {
            return 1
          }
          return 0
        }),
    )
  }
  const clear = () => {
    currencies.value = undefined
  }

  const find = (currency_id: number): Currency | undefined => {
    return currencies.value!.find((currency: Currency) => currency.id === currency_id)
  }
  const findWithCode = (code: string): Currency | undefined => {
    return currencies.value!.find((currency: Currency) => currency.code === code)
  }

  const format = (value: number, currencyId: number, convertFromCurrencyId: number): string => {
    const conversionRate = currencyRateStore.between(convertFromCurrencyId, currencyId)
    const currency = find(currencyId)

    if (!conversionRate || !currency) {
      return formatNumber(value * (conversionRate ?? 1), 2, '.', ',')
    }

    const negative = value < 0
    const positive_value = Math.abs(value)

    return `${negative ? `-` : ``}${currency.symbol_position === CurrencySymbolPosition.LEFT ? currency.symbol : ``}${formatNumber(positive_value * (conversionRate ?? 1), currency.decimals, currency.decimal_separator, currency.thousands_separator)}${currency.symbol_position === CurrencySymbolPosition.RIGHT ? currency.symbol : ``}`
  }

  return {
    currencies,
    load,
    clear,
    find,
    findWithCode,
    format,
  }
})
