<script setup lang="ts">
import type { PropType } from 'vue'
import { computed, ref } from 'vue'

import AdChannelIcon from '@/ui/components/ad-channel-icon.vue'
import BudgetMenu from '@/ui/components/workspace/budgets/budget-menu.vue'
import EffectiveDailyBudget from '@/ui/components/workspace/budgets/effective-daily-budget.vue'
import Graph from '@/ui/components/workspace/budgets/graph.vue'
import type {
  DataPoint,
  DataSeries,
  PotentialDataPoint,
} from '@/ui/components/workspace/budgets/graph.vue'
import MetricForecast from '@/ui/components/workspace/budgets/metric-forecast.vue'
import { AdChannelType } from '@/enums/ad-channel-type.ts'
import { GoogleBudgetLevel } from '@/enums/google-budget-level.ts'
import { MetaBudgetLevel } from '@/enums/meta-budget-level.ts'
import type { Budget } from '@/models/budget.ts'
import type { BudgetSnapshot } from '@/models/budget-snapshot.ts'
import { useCurrencyStore } from '@/stores/currency.ts'
import { useInternationalisationStore } from '@/stores/internationalisation.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const props = defineProps({
  budget: {
    type: Object as PropType<Budget>,
    required: true,
  },
  uuid: {
    type: String,
    required: false,
    default: undefined,
  },
})
const currencyStore = useCurrencyStore()
const workspaceStore = useWorkspaceStore()
const internationalisationStore = useInternationalisationStore()

// const all_ad_channels_have_roas = computed<boolean>(() => {
//   let value = true;
//   if (props.budget.weekly_roas_in_last_window === undefined) {
//     value = false;
//   }
//   return value;
// });
// const any_ad_channels_active = computed<boolean>(() => {
//   let value = false;
//   if (props.budget.active) {
//     value = true;
//   }
//   return value;
// });

const data = computed(() => {
  const series: DataSeries[] = []
  if (!workspaceStore.workspace) {
    return series
  }
  const budget_line_color = 'rgb(121,99,149)'
  const budget_text_color = 'rgba(216, 180, 254, 1)'
  const positive_metric_line_color = '#0faa77'
  const positive_metric_text_color = '#00ffac'
  const negative_metric_line_color = '#c13559'
  const negative_metric_text_color = '#ff6991'
  // const neutral_metric_line_color = "rgba(255,255,255,0.35)";
  // const neutral_metric_text_color = "#ffffff";

  const merged_time_series = workspaceStore
    .workspace!.budgets.map((budget: Budget) => budget.time_series)
    .flat()

  const merged_effective_budgets = workspaceStore
    .workspace!.budgets.map((budget: Budget) => budget.effective_daily_budget)
    .flat()

  // add budget

  const max_budget_y_axis = Math.ceil(
    Math.max(
      ...merged_time_series
        .filter((item: BudgetSnapshot) => item.metrics_yesterday.spend !== undefined)
        .map((item: BudgetSnapshot) => item.metrics_yesterday.spend!),
      ...merged_effective_budgets,
    ),
  )

  const budget_values = props.budget.time_series
    .map((item) => {
      const item_time = item.time
      let date = new Date(item_time)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      date = new Date(date.getTime() - 43200000)
      return {
        date: date,
        value: item.metrics_yesterday.spend ?? undefined,
      } as PotentialDataPoint
    })
    .filter((item: PotentialDataPoint) => item.value !== undefined) as DataPoint[]

  // budget_values.push({
  //   date: new Date(new Date().setHours(23, 59, 59, 999)),
  //   value: props.budget.effective_daily_budget, // todo: adjust for proportion of the forecast day until now?
  //   label_override: 'Daily budget',
  // } as DataPoint)

  series.push({
    label: 'Spend',
    positive_line_color: budget_line_color,
    positive_text_color: budget_text_color,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_budget_y_axis,
    values: budget_values,
    positiveThreshold: 0,
    valueFormatter: (value: number) =>
      currencyStore.format(value, workspaceStore.workspace!.currency.id, props.budget.currency_id),
  })
  const max_ad_profit_y_axis = Math.ceil(
    Math.max(
      ...merged_time_series
        .filter((item: BudgetSnapshot) => item.metrics_yesterday.ad_profit !== undefined)
        .map((item: BudgetSnapshot) => item.metrics_yesterday.ad_profit!),
    ),
  )
  series.push({
    label: 'Ad profit',
    positive_line_color: positive_metric_line_color,
    positive_text_color: positive_metric_text_color,
    negative_line_color: negative_metric_line_color,
    negative_text_color: negative_metric_text_color,
    positiveThreshold: 0.01,
    muted: false,
    y_axis_from: 0,
    y_axis_to: max_ad_profit_y_axis,
    values: props.budget.time_series
      .map((item) => {
        const item_time = item.time
        let date = new Date(item_time)
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        date.setMilliseconds(0)
        date = new Date(date.getTime() - 43200000)
        return {
          date: date,
          value: item.metrics_yesterday.ad_profit ?? undefined,
        } as PotentialDataPoint
      })
      .filter((item: PotentialDataPoint) => item.value !== undefined) as DataPoint[],
    valueFormatter: (value: number) =>
      currencyStore.format(value, workspaceStore.workspace!.currency.id, props.budget.currency_id),
  })

  // filter down data series based on what data we actually have available
  return series.filter((series_: DataSeries) => series_.values.length >= 1)
})

const metrics_open = ref<boolean>(false)
const openMetrics = () => {
  metrics_open.value = true
}

const ready = computed<boolean>(() => {
  return true
})
</script>
<template>
  <div
    :class="`budget card aspect-[7/5] ${ready ? `` : `opacity-75`}`"
    v-if="internationalisationStore.country"
  >
    <div :class="`flex items-start justify-between px-4 pt-4 sm:px-5`">
      <div class="flex flex-col space-y-2.5">
        <div
          class="z-10 flex items-center space-x-3 text-base font-normal leading-5 sm:text-base sm:leading-none"
        >
          <ad-channel-icon class="h-5 w-5" :type="props.budget.ad_channel_type"></ad-channel-icon>
          <span>{{ props.budget.name }}</span>
        </div>
        <div
          class="z-10 flex items-center space-x-2 text-sm font-light uppercase leading-5 text-white/30 sm:leading-none"
        >
          <!--          <ad-channel-icon-->
          <!--            class="h-3.5 w-auto"-->
          <!--            :type="props.budget.ad_channel_type"-->
          <!--          ></ad-channel-icon>-->
          <span
            v-if="
              props.budget.ad_channel_type === AdChannelType.META &&
              props.budget.meta_budget_level === MetaBudgetLevel.CAMPAIGN
            "
            >Meta Campaign</span
          >
          <span
            v-else-if="
              props.budget.ad_channel_type === AdChannelType.META &&
              props.budget.meta_budget_level === MetaBudgetLevel.ADSET
            "
            >Meta Ad Set</span
          >
          <span
            v-else-if="
              props.budget.ad_channel_type === AdChannelType.GOOGLE &&
              props.budget.google_budget_level === GoogleBudgetLevel.SHARED_BUDGET
            "
            >Google Shared Budget</span
          >
          <span
            v-else-if="
              props.budget.ad_channel_type === AdChannelType.GOOGLE &&
              props.budget.google_budget_level === GoogleBudgetLevel.CAMPAIGN
            "
            >Google Campaign</span
          >
        </div>
      </div>

      <div class="flex flex-none items-center space-x-0.5 sm:space-x-1.5">
        <div class="flex items-center">
          <!--          <div-->
          <!--            @click="openMetrics"-->
          <!--            class="mr-1.5 transform cursor-pointer fill-current p-2 pb-3.5 opacity-20 transition-all duration-300 hover:opacity-100"-->
          <!--          >-->
          <!--            <BarChartIcon class="h-6 w-6"></BarChartIcon>-->
          <!--          </div>-->
          <div class="text-sm">
            <!--            <toggle-->
            <!--              v-models="managed"-->
            <!--              :disabled="!props.budget?.budget_group_id"-->
            <!--              :force-off="!props.budget?.budget_group_id"-->
            <!--              :disabled-tooltip="-->
            <!--                workspaceStore.workspace?.budget_groups.length-->
            <!--                  ? `To turn on optimization for this budget you need to move it into a budget`-->
            <!--                  : `To turn on optimization for this budget you need to set up a budget`-->
            <!--              "-->
            <!--            >-->
            <!--              <span :class="managed ? `` : `opacity-30`">Autopilot</span>-->
            <!--            </toggle>-->
          </div>

          <!--        <ad-channel-icon-->
          <!--          class="h-7 w-7"-->
          <!--            v-for="budget in props.budget.workspace"-->
          <!--          :key="budget.id"-->
          <!--          :type="budget.ad_channel_type"-->
          <!--        ></ad-channel-icon>-->
        </div>
        <div>
          <budget-menu
            v-if="false"
            :budget="props.budget"
            @open-metrics="openMetrics"
          ></budget-menu>
        </div>
      </div>
    </div>
    <div class="flex grow items-center justify-center px-6 pt-4 text-sm text-zinc-300">
      <div class="h-full w-full">
        <graph
          v-if="workspaceStore.budgets_timeframe"
          :data="data"
          :timeframe="workspaceStore.budgets_timeframe"
          :uuid="props.uuid ?? `budget-block-${props.budget.id}`"
        ></graph>
      </div>
      <!--      <img :src="graph_empty" class="w-full" alt="graph" />-->
    </div>
    <div
      class="flex w-full items-end justify-between py-4 pl-5 pr-3.5 sm:h-16 sm:flex-row sm:items-center sm:py-0"
    >
      <!--      <popover>-->
      <!--        <template #trigger>-->

      <effective-daily-budget :budget="props.budget"></effective-daily-budget>
      <!--        </template>-->
      <!--      </popover>-->
      <metric-forecast :budget="props.budget"></metric-forecast>
    </div>
    <!--    <pre-->
    <!--      class="text-xs"-->
    <!--      v-for="budget in props.budget.workspace"-->
    <!--      :key="budget.id"-->
    <!--      >{{ budget.metrics }}</pre-->
    <!--    >-->
  </div>
</template>
