import posthog from 'posthog-js'

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(app: any) {
    if (import.meta.env.VITE_POSTHOG_TOKEN) {
      posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
        api_host: '/ingest',
        ui_host: 'https://eu.posthog.com',
        person_profiles: 'always',
      })
      if (
        import.meta.env.VITE_OWN_UUIDS?.length &&
        import.meta.env.VITE_OWN_UUIDS.split(',').includes(localStorage.uuid)
      ) {
        posthog.opt_out_capturing()
      }
      app.config.globalProperties.$posthog = posthog
    }
  },
}
