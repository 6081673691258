<script setup lang="ts">
import { Pencil1Icon } from '@radix-icons/vue'
import type { PropType } from 'vue'
import { ref } from 'vue'

import DialogWrapper from '@/ui/components/dialog-wrapper.vue'
import ManuallyChangeBudget from '@/ui/dialogs/budgets/manually-change-budget.vue'
import type { Budget } from '@/models/budget.ts'
import { useCurrencyStore } from '@/stores/currency.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const currencyStore = useCurrencyStore()
const workspaceStore = useWorkspaceStore()

const props = defineProps({
  budget: {
    type: Object as PropType<Budget>,
    required: true,
  },
})

const manually_change_budget_dialog_open = ref<boolean>(false)

const changeBudget = () => {
  manually_change_budget_dialog_open.value = true
}
</script>
<template>
  <div
    class="flex flex-col items-start font-sans"
    v-if="props.budget.lifetime_budget && props.budget.budget_remaining && workspaceStore.workspace"
  >
    <span class="text-xs text-purple-300/70">Remaining budget</span>
    <span class="font-mono text-sm text-purple-300"
      >{{
        currencyStore.format(
          props.budget.budget_remaining,
          workspaceStore.workspace.currency.id,
          props.budget.currency_id,
        )
      }}
      /
      {{
        currencyStore.format(
          props.budget.lifetime_budget,
          workspaceStore.workspace.currency.id,
          props.budget.currency_id,
        )
      }}</span
    >
  </div>
  <div class="flex flex-col items-start" v-else-if="workspaceStore.workspace">
    <span class="font-sans text-xs text-purple-300/70">Daily budget</span>
    <div class="flex items-center space-x-[5px]">
      <span class="font-mono text-sm text-purple-300">{{
        currencyStore.format(
          props.budget.effective_daily_budget,
          workspaceStore.workspace.currency.id,
          props.budget.currency_id,
        )
      }}</span>
      <Pencil1Icon
        @click="changeBudget"
        class="h-4 w-4 cursor-pointer text-purple-300/40 transition-all duration-75 hover:text-white"
      />
    </div>
  </div>
  <dialog-wrapper v-model="manually_change_budget_dialog_open">
    <manually-change-budget
      :budget="props.budget"
      @close="
        () => {
          manually_change_budget_dialog_open = false
        }
      "
    ></manually-change-budget>
  </dialog-wrapper>
</template>
