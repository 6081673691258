<script setup lang="ts">
import { watchOnce } from '@vueuse/core'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import AdChannelIcon from '@/ui/components/ad-channel-icon.vue'
import DialogWrapper from '@/ui/components/dialog-wrapper.vue'
import PageLayout from '@/ui/components/page-layout.vue'
import ConnectAdChannelDialog from '@/ui/dialogs/ad-channels/connect.vue'
import ConnectMoreOrContinueDialog from '@/ui/dialogs/ad-channels/connect-more-or-continue.vue'
import GoogleAdAccountOptionsDialog from '@/ui/dialogs/ad-channels/google-ad-account-options.vue'
import MetaAdAccountOptionsDialog from '@/ui/dialogs/ad-channels/meta-ad-account-options.vue'
import { adChannelTypeDisplayName } from '@/enums/ad-channel-type.ts'
import type { GoogleAdAccountOption } from '@/models/google-ad-account-option.ts'
import type { MetaAdAccountOption } from '@/models/meta-ad-account-option.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'
import type { AdChannel } from '@/models/ad-channel.ts'
import type { WorkspaceRaw } from '@/models/workspace.ts'
import { api } from '@/helpers/api.ts'
import { useAuthStore } from '@/stores/auth.ts'

const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()
const route = useRoute()
const router = useRouter()

const connect_dialog_open = ref<boolean>(false)
const connect_more_or_continue_dialog_open = ref<boolean>(false)

const meta_options_dialog_open = ref<boolean>(false)
const meta_access_token = ref<string | undefined>(undefined)
const meta_ad_channels = ref<MetaAdAccountOption[] | undefined>(undefined)

const google_options_dialog_open = ref<boolean>(false)
const google_access_token = ref<string | undefined>(undefined)
const google_refresh_token = ref<string | undefined>(undefined)
const google_ad_channels = ref<GoogleAdAccountOption[] | undefined>(undefined)

const loaded = async () => {
  if (workspaceStore.workspace!.ad_channels.length == 0 && route.query.option === undefined) {
    await router.push({
      name: 'workspace',
      params: {
        workspace: workspaceStore.workspace!.id,
      },
      // query: {
      //   setup: "true",
      // },
    })
    return
  }
  if (route.query.option === 'meta' && route.query.access_token && route.query.ad_channels) {
    meta_access_token.value = route.query.access_token as string
    meta_ad_channels.value = JSON.parse(route.query.ad_channels as string) as MetaAdAccountOption[]

    meta_options_dialog_open.value = true
  } else if (
    route.query.option === 'google' &&
    route.query.access_token &&
    route.query.refresh_token &&
    route.query.ad_channels
  ) {
    google_access_token.value = route.query.access_token as string
    google_refresh_token.value = route.query.refresh_token as string
    google_ad_channels.value = JSON.parse(
      route.query.ad_channels as string,
    ) as GoogleAdAccountOption[]

    google_options_dialog_open.value = true
  } else if (route.query.setup && route.query.done) {
    connect_more_or_continue_dialog_open.value = true
  } else if (route.query.setup) {
    // connect_dialog_open.value = true;
  }
}

if (workspaceStore.workspace) {
  loaded()
} else {
  watchOnce(() => workspaceStore.workspace, loaded)
}

const has_ad_channels = computed<boolean | undefined>(() => {
  if (workspaceStore.workspace === undefined) {
    return undefined
  }
  return workspaceStore.workspace.ad_channels.length >= 1
})

const proceedWithSetup = () => {
  router.push({
    name: 'budgets',
    params: {
      workspace: workspaceStore.workspace!.id,
    },
    // query: {
    //   setup: "true",
    // },
  })
}

const syncing = ref<boolean>(false)
const sync = (adChannel: AdChannel) => {
  syncing.value = true

  api
    .put(`ad-channel/sync`, {
      id: adChannel.id,
    })
    .finally(() => {
      syncing.value = false
    })
}
</script>
<template>
  <page-layout v-if="workspaceStore.workspace" :tabs="has_ad_channels">
    <template v-if="has_ad_channels">
      <div class="mx-9">
        <table class="table table-auto">
          <thead class="text-left">
            <tr>
              <th>Name</th>
              <th>Type</th>
              <!--              <th>Last synced</th>-->
            </tr>
          </thead>
          <tbody>
            <tr v-for="ad_channel in workspaceStore.workspace.ad_channels" :key="ad_channel.id">
              <td>{{ ad_channel.name }}</td>
              <td>
                <div class="flex items-center space-x-4">
                  <ad-channel-icon :type="ad_channel.type" class="h-5 w-auto"></ad-channel-icon>
                  <span>{{ adChannelTypeDisplayName(ad_channel.type) }}</span>
                  <div class="pl-10" v-if="authStore.user?.admin && !syncing">
                    <span
                      @click="
                        () => {
                          sync(ad_channel)
                        }
                      "
                      class="link"
                      >Sync campaigns</span
                    >
                  </div>
                </div>
              </td>
              <!--              <td></td>-->
            </tr>
          </tbody>
        </table>
        <button class="button mt-20" @click="connect_dialog_open = true">Connect ad channel</button>
      </div>
    </template>
    <dialog-wrapper v-model="connect_dialog_open">
      <connect-ad-channel-dialog @cancel="connect_dialog_open = false"></connect-ad-channel-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="connect_more_or_continue_dialog_open">
      <connect-more-or-continue-dialog
        @cancel="connect_more_or_continue_dialog_open = false"
        @add-more="
          () => {
            connect_dialog_open = true
            connect_more_or_continue_dialog_open = false
          }
        "
        @continue="proceedWithSetup"
      ></connect-more-or-continue-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="meta_options_dialog_open">
      <meta-ad-account-options-dialog
        v-if="meta_access_token && meta_ad_channels"
        :access-token="meta_access_token"
        :ad-channels="meta_ad_channels"
        @cancel="meta_options_dialog_open = false"
        @complete="
          () => {
            meta_options_dialog_open = false
            // if (route.query.setup) {
            //   connect_more_or_continue_dialog_open = true;
            // }
          }
        "
      ></meta-ad-account-options-dialog>
    </dialog-wrapper>
    <dialog-wrapper v-model="google_options_dialog_open">
      <google-ad-account-options-dialog
        v-if="google_access_token && google_refresh_token && google_ad_channels"
        :access-token="google_access_token"
        :refresh-token="google_refresh_token"
        :ad-channels="google_ad_channels"
        @cancel="google_options_dialog_open = false"
        @complete="
          () => {
            google_options_dialog_open = false
            // if (route.query.setup) {
            //   connect_more_or_continue_dialog_open = true;
            // }
          }
        "
      ></google-ad-account-options-dialog>
    </dialog-wrapper>
  </page-layout>
</template>
