<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import team_management from '@/assets/docs/team-management.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'
const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      If you want to add colleagues to a workspace, you can do so in workspace settings.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="team_management" />
    </div>
    <p>There are 4 different roles in Charter:</p>
    <ul>
      <li><span class="inline font-bold">Admin</span></li>
      <ul>
        <li>Full access to the account</li>
        <li>Automatically added to all new workspaces</li>
        <li>Can add/remove team members (all roles)</li>
        <li>Responsible for billing</li>
      </ul>
    </ul>
    <ul>
      <li><span class="inline font-bold">Editor (account-wide)</span></li>
      <ul>
        <li>Full access to the account</li>
        <li>Automatically added to all new workspaces</li>
        <li>Can add/remove read-only members</li>
      </ul>
    </ul>
    <ul>
      <li><span class="inline font-bold">Editor (workspaces)</span></li>
      <ul>
        <li>Access only to the workspaces they are invited to</li>
        <li>Can add/remove read-only members</li>
      </ul>
    </ul>
    <ul>
      <li><span class="inline font-bold">Read-only</span></li>
      <ul>
        <li>Access to view the workspaces they are invited to</li>
      </ul>
    </ul>
  </div>
  <div class="border-l-4 border-[#9f69fb] bg-purple-50 p-4 text-purple-800" role="alert">
    <p class="font-bold">Example</p>
    <p>
      You own a marketing agency and create an account in Charter, you are automatically the admin
      of your account. You invite a colleague as an editor to work on a client’s ads and your
      colleague invites the client as a read-only viewer so they can see how their ads are
      progressing.
    </p>
  </div>
  <p></p>
</template>
