import { Preferences } from '@capacitor/preferences'
import { defineStore } from 'pinia'
import { computed, getCurrentInstance, ref, watch } from 'vue'

import type { Country } from '@/models/country.ts'
import type { Language } from '@/models/language.ts'
import { useCountryStore } from '@/stores/country.ts'
import { useLanguageStore } from '@/stores/language.ts'

export const useInternationalisationStore = defineStore('internationalisation', () => {
  const countryStore = useCountryStore()
  const languageStore = useLanguageStore()
  const instance = getCurrentInstance()

  const browser_locale = navigator.language.replace('-', '_')
  const browser_language_code = computed(() => {
    return browser_locale.split('_')[0].toLowerCase()
  })
  const browser_language = computed(() => {
    if (!languageStore.languages) {
      return undefined
    }
    return languageStore.languages.find(
      (language: Language) => language.code === browser_language_code.value,
    )
  })
  const ip_country = ref<Country | undefined>()
  const browser_currency = computed(() => {
    return ip_country.value?.currency
  })
  const setIpCountry = (country_id: number) => {
    ip_country.value = countryStore.find(country_id)
  }

  const chosen_country = ref<Country | undefined>(window.chosen_country)
  const chooseCountry = (country: Country) => {
    chosen_country.value = country
    window.chosen_country = chosen_country.value
  }

  const chosen_language = ref<Language | undefined>(window.chosen_language)
  const chooseLanguage = (language: Language) => {
    chosen_language.value = language
    window.chosen_language = chosen_language.value
  }

  const country = computed<Country | undefined>(() => {
    return chosen_country.value ?? ip_country.value
  })

  const language = computed<Language | undefined>(() => {
    return chosen_language.value ?? country.value?.language
  })

  const locale = computed<string>(() => {
    if (language.value && country.value) {
      return `${language.value!.code.toLowerCase()}_${country.value!.iso_alpha_2.toUpperCase()}`
    }
    return window.remembered_locale ?? browser_locale
  })

  watch(chosen_country, (after) => {
    if (after) {
      Preferences.set({
        key: 'chosen_country',
        value: JSON.stringify(after),
      }).then(() => {})
    } else {
      Preferences.remove({ key: 'chosen_country' }).then(() => {})
    }
  })

  watch(chosen_language, (after) => {
    if (after) {
      Preferences.set({
        key: 'chosen_language',
        value: JSON.stringify(after),
      }).then(() => {})
    } else {
      Preferences.remove({ key: 'chosen_language' }).then(() => {})
    }
  })

  watch(locale, (after, before) => {
    if (before && after) {
      if (instance) {
        instance.appContext.config.globalProperties.$i18n.locale = after
      }
    }
    if (after) {
      Preferences.set({
        key: 'remembered_locale',
        value: after,
      }).then(() => {})
    } else {
      Preferences.remove({ key: 'remembered_locale' }).then(() => {})
    }
    window.remembered_locale = after
  })

  return {
    browser_locale,
    ip_country,
    setIpCountry,
    browser_language_code,
    browser_language,
    browser_currency,

    chosen_country,
    chooseCountry,
    chosen_language,
    chooseLanguage,

    country,
    language,
    locale,
  }
})
