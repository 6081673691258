<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import budget_assign from '@/assets/docs/budget-assign.png'
import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p>
      Once you've created a budget group, you can then move a budget into it by clicking the ∨
      button in the top right of the budget box. Then click “budget > assign” and select the budget
      group you’d like to move the budget into.
    </p>
    <div class="rounded-md border border-white/30 pl-8 pr-8">
      <img class="object-cover" :src="budget_assign" />
    </div>
  </div>
</template>
