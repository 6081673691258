<script setup lang="ts">
import type { PropType } from 'vue'

import BingAdsIcon from '@/ui/components/icons/bing-icon.vue'
import GoogleAdsIcon from '@/ui/components/icons/google-ads-icon.vue'
import LinkedinIcon from '@/ui/components/icons/linkedin-icon.vue'
import MetaIcon from '@/ui/components/icons/meta-icon.vue'
import PinterestIcon from '@/ui/components/icons/pinterest-icon.vue'
import RedditIcon from '@/ui/components/icons/reddit-icon.vue'
import SnapchatIcon from '@/ui/components/icons/snapchat-icon.vue'
import TiktokIcon from '@/ui/components/icons/tiktok-icon.vue'
import XIcon from '@/ui/components/icons/x-icon.vue'
import YoutubeIcon from '@/ui/components/icons/youtube-icon.vue'
import { AdChannelType } from '@/enums/ad-channel-type.ts'

const props = defineProps({
  type: {
    type: String as PropType<AdChannelType>,
    required: true,
  },
})
</script>
<template>
  <meta-icon v-if="props.type === AdChannelType.META"></meta-icon>
  <google-ads-icon v-else-if="props.type === AdChannelType.GOOGLE"></google-ads-icon>
  <tiktok-icon v-else-if="props.type === AdChannelType.TIKTOK_ADS"></tiktok-icon>
  <linkedin-icon v-else-if="props.type === AdChannelType.LINKEDIN_ADS"></linkedin-icon>
  <reddit-icon v-else-if="props.type === AdChannelType.REDDIT_ADS"></reddit-icon>
  <youtube-icon v-else-if="props.type === AdChannelType.YOUTUBE_ADS"></youtube-icon>
  <x-icon v-else-if="props.type === AdChannelType.TWITTER_ADS"></x-icon>
  <pinterest-icon v-else-if="props.type === AdChannelType.PINTEREST_ADS"></pinterest-icon>
  <snapchat-icon v-else-if="props.type === AdChannelType.SNAPCHAT"></snapchat-icon>
  <bing-ads-icon v-else-if="props.type === AdChannelType.BING_ADS"></bing-ads-icon>
</template>
