<script setup lang="ts">
const emit = defineEmits<{
  (e: 'input', event: InputEvent): void
}>()

const props = defineProps({
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const model = defineModel({ type: String })

const onInput = (event: Event) => {
  emit('input', event as InputEvent)
}
</script>

<template>
  <input
    v-model="model"
    :readonly="props.readonly"
    @input="onInput"
    :class="`input ${props.disabled ? 'disabled' : ''}`"
  />
</template>
