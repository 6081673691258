<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'

import Toast from '@/ui/components/toast.vue'
import { ToastVariant } from '@/enums/toast-variant.ts'

onMounted(() => {
  window.addEventListener(`user-error`, show)
})
onBeforeUnmount(() => {
  window.removeEventListener(`user-error`, show)
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const show = (e: any) => {
  title.value = e.detail.title
  message.value = e.detail.message
  open.value = true
}

const open = ref(false)
const title = ref<string | undefined>()
const message = ref<string | undefined>()
</script>
<template>
  <toast
    v-model="open"
    :variant="ToastVariant.ERROR"
    :duration="3000"
    :title="title || 'That doesn\'t look right'"
    :description="message || `Please try again`"
    :short-description="message ?? `Please try again`"
  ></toast>
</template>
