<script setup lang="ts">
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import bg from '@/assets/bg.png'
import AuthForm from '@/ui/components/auth/options.vue'
import PageLayout from '@/ui/components/page-layout.vue'
import { AuthContext } from '@/enums/auth-context.ts'

const route = useRoute()
const router = useRouter()

const proceed = () => {
  if (route.query.to) {
    router.replace(JSON.parse(atob(route.query.to as string)))
  } else {
    router.replace({ name: 'workspaces' })
  }
}

const back = () => {
  router.push({
    name: 'home',
  })
}
const stage = ref(0)
</script>

<template>
  <div
    :style="`
      background-color: #003335;
      background-image: url(${bg});
      background-size: cover;
      background-position: top;
      background-attachment: fixed;
      background-blend-mode: soft-light;
    `"
  >
    <div class="flex min-h-screen" style="background-color: rgba(0, 0, 0, 0.82)">
      <page-layout :centered="true" :tabs="false" :has-back-button="true" @back="back">
        <auth-form
          v-model="stage"
          @proceed="proceed"
          :auth-context="AuthContext.SIGN_IN"
          :email-auth-route="{
            name: 'sign-in-with-email',
          }"
        ></auth-form
      ></page-layout>
    </div>
  </div>
</template>
