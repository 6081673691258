import axios from 'axios'
import type { InternalAxiosRequestConfig } from 'axios'

import {
  clientError,
  connectionAbortedError,
  connectionError,
  connectionTimeoutError,
  serverError,
} from '@/helpers/errors.ts'
import { useAuthStore } from '@/stores/auth.ts'
import { event } from '@/helpers/events.ts'

import { UAParser } from 'ua-parser-js'
import { isBot } from 'ua-parser-js/helpers'

function generateUuid() {
  // Check if crypto.randomUUID is available
  if (window.crypto && typeof window.crypto.randomUUID === 'function') {
    return window.crypto.randomUUID()
  }
  // Fallback to custom uuidv4 function
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function client(): any {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const client: any = UAParser(window.navigator.userAgent ?? 'unknown')

  client['is_bot'] = isBot(client.ua)
  if (client.ua.startsWith('vercel-screenshot/')) {
    client['is_bot'] = true
  }
  if (screen.orientation?.type) {
    client['orientation'] = {
      type: screen.orientation?.type,
      angle: screen.orientation?.angle,
    }
  }
  client['colordepth'] = window.screen.colorDepth
  client['pixeldepth'] = window.screen.pixelDepth
  client['viewportwidth'] = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  )
  client['viewportheight'] = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  )
  client['availwidth'] = window.screen.availWidth
  client['availheight'] = window.screen.availHeight
  client['screenwidth'] = window.screen.width
  client['screenheight'] = window.screen.height
  client['istouch'] = 'ontouchstart' in window || navigator.maxTouchPoints > 0
  client['ishover'] = window.matchMedia('(hover: hover)').matches
  client['pointer'] = window.matchMedia('(pointer: fine)').matches
    ? 'fine'
    : window.matchMedia('(pointer: coarse)').matches
      ? 'coarse'
      : 'none'
  client['pixelratio'] = window.devicePixelRatio
  client['dark'] = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

  if (document.referrer.length >= 1) {
    client['referrer'] = document.referrer
  }
  client['app'] = 'web'

  client['app_version'] = document.querySelector(`meta[name="version"]`)?.getAttribute('content')

  client['locale'] = navigator.language.replace('-', '_').toLowerCase()
  client['language_code'] = client['locale'].split('_')[0]
  client['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
  client['chosen_country_code'] = window.chosen_country?.iso_alpha_2
  client['chosen_language_code'] = window.chosen_language?.code

  if (localStorage.uuid === undefined) {
    localStorage.uuid = generateUuid()
    event('new-prospect').then(() => {}) // todo: dont fire this on certain routes (sign up via invite, etc)
  }
  client['uuid'] = localStorage.uuid

  return client
}

export const api = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (url: string, config?: any) => {
    return axios.get(
      !url.split('?', 2)[0].includes('://')
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, '')}`
        : url,
      config,
    ) as Promise<unknown>
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: (url: string, data?: any, config?: any) => {
    return axios.post(
      !url.split('?', 2)[0].includes('://')
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, '')}`
        : url,
      data ?? {},
      config,
    ) as Promise<unknown>
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put: (url: string, data?: any, config?: any) => {
    return axios.put(
      !url.split('?', 2)[0].includes('://')
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, '')}`
        : url,
      data ?? {},
      config,
    ) as Promise<unknown>
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete: (url: string, config?: any) => {
    return axios.delete(
      !url.split('?', 2)[0].includes('://')
        ? `${import.meta.env.VITE_API_URL}/${url.replace(/^(\/)/, '')}`
        : url,
      config,
    ) as Promise<unknown>
  },
}

export class HttpResponseError extends Error {
  status: number
  method: string
  url: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(status: number, method: string, url: string, data: any) {
    super(`${method.toUpperCase()} ${url.split('?', 2)[0]}`)
    this.name = `HTTP ${status}`
    this.status = status
    this.method = method.toUpperCase()
    this.url = url
    this.data = data
  }
}

export class HttpTimeoutError extends Error {
  method: string
  url: string
  milliseconds: number
  constructor(method: string, url: string, milliseconds: number) {
    super(`${method.toUpperCase()} ${url.split('?', 2)[0]}`)
    this.name = `HTTP Timeout`
    this.method = method.toUpperCase()
    this.url = url
    this.milliseconds = milliseconds
  }
}

export class HttpAbortError extends Error {
  method: string
  url: string
  milliseconds: number
  constructor(method: string, url: string, milliseconds: number) {
    super(`${method.toUpperCase()} ${url.split('?', 2)[0]}`)
    this.name = `HTTP Aborted`
    this.method = method.toUpperCase()
    this.url = url
    this.milliseconds = milliseconds
  }
}

export class HttpConnectionError extends Error {
  method: string
  url: string
  constructor(method: string, url: string) {
    super(`${method.toUpperCase()} ${url.split('?', 2)[0]}`)
    this.name = `HTTP Connection Refused`
    this.method = method.toUpperCase()
    this.url = url
  }
}

axios.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const authStore = useAuthStore()
  if (
    request.url === import.meta.env.VITE_API_URL ||
    request.url?.startsWith(import.meta.env.VITE_API_URL) + '?' ||
    request.url?.startsWith(import.meta.env.VITE_API_URL) + '/'
  ) {
    if (!request.params) {
      request.params = {}
    }
    request.headers['X-Socket-Id'] = window.Echo.socketId()
    if (authStore.token) {
      request.headers.Authorization = `Bearer ${authStore.token}`
    }
  }
  if (request.timeout === 0) {
    request.timeout = 400000
    // request.timeout = 30000;
  }
  request.headers['X-Client'] = JSON.stringify(client())

  return request
})

axios.interceptors.response.use(
  (response) => {
    if (response.headers['x-client']) {
      localStorage.uuid = response.headers['x-client']
    }
    if (response.headers['x-retry']) {
      return axios.create()({
        ...response.config,
        headers: {
          ...response.config.headers,
          'x-retry-count': (response.config.headers['x-retry-count'] || 0) + 1,
        },
      })
    }
    return response.data
  },
  async (error) => {
    if (error.response) {
      if (error.response.headers['x-client']) {
        localStorage.uuid = error.response.headers['x-client']
      }
      if (error.response.headers['x-retry']) {
        return axios.create()({
          ...error.response.config,
          headers: {
            ...error.response.config.headers,
            'x-retry-count': (error.response.config.headers['x-retry-count'] || 0) + 1,
          },
        })
      }
    }
    const authStore = useAuthStore()
    if (error.code === 'ETIMEDOUT') {
      const exception = new HttpTimeoutError(
        error.config.method,
        error.config.url,
        error.config.timeout,
      )
      // captureException(exception)
      connectionTimeoutError()
      return Promise.reject(exception)
    } else if (error.code === 'ECONNABORTED' || error.code === 'ERR_CANCELED') {
      const exception = new HttpAbortError(
        error.config.method,
        error.config.url,
        error.config.timeout,
      )
      // captureException(exception)
      connectionAbortedError()
      return Promise.reject(exception)
    } else if (error.code === 'ERR_NETWORK') {
      const exception = new HttpConnectionError(error.config.method, error.config.url)
      // captureException(exception)
      connectionError()
      return Promise.reject(exception)
    }
    if (error.response) {
      if (
        error.config.url === import.meta.env.VITE_API_URL ||
        error.config.url?.startsWith(import.meta.env.VITE_API_URL) + '?' ||
        error.config.url?.startsWith(import.meta.env.VITE_API_URL) + '/'
      ) {
        if (error.response.status === 401 && !error.config.url.endsWith('/close')) {
          await authStore.signOut()
        } else if (error.response.status === 402) {
          // TODO: reload the workspace details to get up to date subscribed flag
          // - be careful about this firing multiple times, use some type of lock
          // - or always return that resource with a 402 from the backend and load it automatically here?
          // - it could be some response header that is added any time there is a 402
        }
      }
      const exception = new HttpResponseError(
        error.response.status,
        error.config.method.toUpperCase(),
        error.config.url,
        error.response.data,
      )
      if (error.response.status >= 500) {
        serverError(exception.data?.message)
      } else if (![401, 402, 403].includes(error.response.status)) {
        clientError(exception.data?.message)
      }
      return Promise.reject(exception)
    }
    return Promise.reject(error)
  },
)
