<script setup lang="ts"></script>
<template>
  <svg
    width="768"
    height="111"
    viewBox="0 0 768 111"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M742.719 40.044C745.369 35.7253 748.805 32.3391 753.025 29.8853C757.344 27.4315 762.251 26.2046 767.748 26.2046V47.847H762.301C755.823 47.847 750.915 49.3684 747.578 52.4111C744.339 55.4538 742.719 60.7539 742.719 68.3116V108.946H722.107V27.3824H742.719V40.044Z"
    />
    <path
      d="M705.589 66.3986C705.589 69.3431 705.393 71.9932 705 74.3488H645.373C645.864 80.2379 647.925 84.8511 651.557 88.1882C655.188 91.5254 659.654 93.1939 664.954 93.1939C672.61 93.1939 678.058 89.9058 681.297 83.3297H703.528C701.172 91.1818 696.657 97.6598 689.983 102.764C683.309 107.769 675.113 110.272 665.396 110.272C657.544 110.272 650.477 108.555 644.195 105.119C638.012 101.586 633.153 96.6292 629.62 90.2494C626.185 83.8695 624.467 76.5082 624.467 68.1653C624.467 59.7243 626.185 52.3138 629.62 45.934C633.055 39.5541 637.865 34.6465 644.048 31.2112C650.232 27.7759 657.348 26.0583 665.396 26.0583C673.15 26.0583 680.07 27.7268 686.155 31.064C692.339 34.4012 697.099 39.1615 700.436 45.3451C703.872 51.4305 705.589 58.4483 705.589 66.3986ZM684.241 60.5095C684.143 55.2093 682.229 50.9888 678.499 47.8479C674.77 44.6089 670.206 42.9894 664.807 42.9894C659.703 42.9894 655.385 44.5598 651.851 47.7007C648.416 50.7434 646.306 55.013 645.52 60.5095H684.241Z"
    />
    <path
      d="M595.214 44.3139V83.7708C595.214 86.5191 595.852 88.5312 597.128 89.8071C598.502 90.985 600.759 91.5739 603.9 91.5739H613.47V108.947H600.514C583.141 108.947 574.455 100.506 574.455 83.6236V44.3139H564.738V27.3828H574.455V7.21262H595.214V27.3828H613.47V44.3139H595.214Z"
    />
    <path
      d="M530.832 40.044C533.482 35.7253 536.918 32.3391 541.138 29.8853C545.457 27.4315 550.365 26.2046 555.861 26.2046V47.847H550.414C543.936 47.847 539.028 49.3684 535.691 52.4111C532.452 55.4538 530.832 60.7539 530.832 68.3116V108.946H510.221V27.3824H530.832V40.044Z"
    />
    <path
      d="M403.667 67.8708C403.667 59.6261 405.286 52.3138 408.525 45.934C411.863 39.5541 416.328 34.6465 421.923 31.2112C427.616 27.7759 433.947 26.0583 440.915 26.0583C447.001 26.0583 452.301 27.2852 456.816 29.739C461.429 32.1927 465.11 35.2845 467.858 39.0143V27.3833H488.617V108.947H467.858V97.0218C465.208 100.85 461.527 104.04 456.816 106.592C452.203 109.045 446.854 110.272 440.768 110.272C433.898 110.272 427.616 108.506 421.923 104.972C416.328 101.439 411.863 96.482 408.525 90.1022C405.286 83.6242 403.667 76.2137 403.667 67.8708ZM467.858 68.1653C467.858 63.1596 466.876 58.89 464.913 55.3565C462.95 51.7249 460.3 48.9767 456.963 47.1118C453.626 45.1487 450.043 44.1672 446.216 44.1672C442.388 44.1672 438.854 45.0997 435.615 46.9645C432.376 48.8294 429.726 51.5777 427.665 55.2093C425.702 58.7427 424.72 62.9632 424.72 67.8708C424.72 72.7784 425.702 77.0971 427.665 80.8268C429.726 84.4584 432.376 87.2558 435.615 89.2188C438.952 91.1818 442.486 92.1633 446.216 92.1633C450.043 92.1633 453.626 91.2309 456.963 89.366C460.3 87.403 462.95 84.6547 464.913 81.1213C466.876 77.4897 467.858 73.171 467.858 68.1653Z"
    />
    <path
      d="M356.094 26.2065C362.278 26.2065 367.774 27.5806 372.584 30.3288C377.393 32.9789 381.123 36.9541 383.773 42.2542C386.521 47.4563 387.895 53.738 387.895 61.0993V108.948H367.283V63.8966C367.283 57.4186 365.664 52.462 362.425 49.0267C359.186 45.4932 354.769 43.7265 349.174 43.7265C343.482 43.7265 338.967 45.4932 335.63 49.0267C332.391 52.462 330.771 57.4186 330.771 63.8966V108.948H310.159V0H330.771V37.543C333.421 34.0095 336.955 31.2613 341.371 29.2982C345.788 27.237 350.696 26.2065 356.094 26.2065Z"
    />
    <path
      d="M190.671 57.4171C190.671 47.3075 192.929 38.2775 197.444 30.3272C202.057 22.2788 208.29 16.0462 216.142 11.6294C224.092 7.1144 232.975 4.85692 242.79 4.85692C254.274 4.85692 264.334 7.80147 272.971 13.6906C281.609 19.5797 287.645 27.7262 291.08 38.1303H267.377C265.021 33.2227 261.684 29.542 257.365 27.0882C253.145 24.6345 248.237 23.4076 242.643 23.4076C236.655 23.4076 231.306 24.8308 226.595 27.6772C221.982 30.4254 218.35 34.3514 215.7 39.4553C213.148 44.5592 211.872 50.5465 211.872 57.4171C211.872 64.1895 213.148 70.1768 215.7 75.3788C218.35 80.4827 221.982 84.4578 226.595 87.3042C231.306 90.0525 236.655 91.4266 242.643 91.4266C248.237 91.4266 253.145 90.1997 257.365 87.7459C261.684 85.194 265.021 81.4642 267.377 76.5566H291.08C287.645 87.0588 281.609 95.2545 272.971 101.144C264.432 106.935 254.372 109.83 242.79 109.83C232.975 109.83 224.092 107.622 216.142 103.205C208.29 98.6898 202.057 92.4572 197.444 84.5069C192.929 76.5566 190.671 67.5267 190.671 57.4171Z"
    />
    <path
      d="M11.0803 34.0993L113.371 6.69063C116.812 5.67513 123.273 4.81979 127.752 7.00687C131.425 8.80042 127.738 12.7409 121.682 15.583L59.6861 43.0807C46.3929 49.8903 35.8646 51.5304 36.6054 67.5706C38.4571 107.659 38.0223 112.129 28.4543 98.9399C20.9073 85.2097 5.2573 55.6744 3.03342 47.3748C0.809552 39.0752 7.47141 35.0663 11.0803 34.0993Z"
    />
  </svg>
</template>
