<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'

import LoadingBar from '@/ui/components/loading-bar.vue'
import { AuthContext, authContextRoute } from '@/enums/auth-context.ts'
import { api } from '@/helpers/api.ts'
import { waitFor } from '@/helpers/wait-for.ts'
import type { AuthenticationResult, AuthenticationSuccess } from '@/models/authentication-result.ts'
import { useAuthStore } from '@/stores/auth.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const workspaceStore = useWorkspaceStore()

if (route.query.error) {
  if (route.query.state) {
    router.replace(authContextRoute(route.query.state as AuthContext))
  } else {
    router.replace({
      name: 'home',
    })
  }
}

const exchangeCodeForToken = async () => {
  if (route.query.error) {
    return
  }
  const code = route.query.code

  api
    .put('auth/meta', {
      code,
      redirect_uri: `${window.location.origin}/oauth/meta`,
    })
    .then(async (result: unknown) => {
      await waitFor(() => workspaceStore.workspaces !== undefined)
      if ((result as AuthenticationResult).success) {
        authStore.signIn(result as AuthenticationSuccess).then(() => {
          router.replace({
            name: 'workspaces',
          })
        })
      }
    })
}

exchangeCodeForToken()
</script>
<template>
  <div class="flex grow items-center justify-center">
    <loading-bar></loading-bar>
  </div>
</template>
