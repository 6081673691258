<script setup lang="ts">
import DialogBody from '@/ui/components/dialog-body.vue'
import DialogTitlebar from '@/ui/components/dialog-titlebar.vue'
import StyledButton from '@/ui/components/form/styled-button.vue'
import { ButtonType } from '@/enums/button-type.ts'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'add-more'): void
  (e: 'continue'): void
}>()
</script>

<template>
  <div class="dialog-box">
    <dialog-titlebar
      @close="emit('cancel')"
      title="Ad channel connected successfully"
    ></dialog-titlebar>
    <dialog-body>
      <div class="flex flex-col space-y-7">
        <div class="flex flex-col items-start space-y-4">
          <styled-button class="w-full" :type="ButtonType.REGULAR" @click="emit('add-more')">
            Connect another ad channel
          </styled-button>
          <styled-button class="w-full" :type="ButtonType.PRIMARY" @click="emit('continue')">
            Go to dashboard
          </styled-button>
        </div>
      </div>
    </dialog-body>
  </div>
</template>
