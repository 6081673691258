import { defineStore } from 'pinia'

import { nullsToUndefined } from '@/helpers/nulls-to-undefined.ts'
import type { Budget, BudgetRaw } from '@/models/budget.ts'

export const useBudgetStore = defineStore('budget', () => {
  const convertRawBudget = (budget_raw: BudgetRaw): Budget => {
    return nullsToUndefined({
      ...budget_raw,
    })
  }

  return {
    convertRawBudget,
  }
})
