<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router'

import { contents, isSelected, nextItem } from '@/ui/components/docs/contents.ts'
const route = useRoute()
</script>
<template>
  <div class="flex select-none flex-col space-y-[20px]">
    <div class="flex flex-col space-y-2" v-for="item in contents" :key="item.title">
      <component
        :class="`transition-all duration-75 ${isSelected(route, item, true) ? `text-white` : `text-white/60`} ${isSelected(route, item, false) ? `font-semibold` : `font-medium`}`"
        :is="(item.route ?? nextItem(item, contents)?.route) ? RouterLink : 'span'"
        :to="item.route ?? nextItem(item, contents)?.route"
        >{{ item.title }}</component
      >
      <div class="flex flex-col" v-if="item.items?.length">
        <component
          :class="`border-l-[1px] py-3 pl-5 transition-all duration-75 ${isSelected(route, subitem, true) ? `border-white font-semibold` : `border-white/30 text-white/60`}`"
          :is="subitem.route ? RouterLink : 'span'"
          :to="subitem.route"
          v-for="subitem in item.items"
          :key="subitem.title"
          >{{ subitem.title }}</component
        >
      </div>
    </div>
  </div>
</template>
