<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import type { Item } from '@/ui/components/docs/contents.ts'
import { contents, selectedItem } from '@/ui/components/docs/contents.ts'

const route = useRoute()

const page = computed<Item | undefined>(() => selectedItem(route, contents))
</script>
<template>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <h1>{{ page.title }}</h1>
    <p class="text-xl">
      Each account receives a 1 month free trial, with no upfront card details needed. After that,
      billing runs on a usage basis, billed monthly.
    </p>
    <p>
      Charter’s billing period runs by calendar month regardless of when you first created your
      account. All users are charged for their usage during the billing period on the 1st day of the
      proceeding month.
    </p>
  </div>
  <div class="border-l-4 border-[#9f69fb] bg-purple-50 p-4 text-purple-800" role="alert">
    <p class="font-bold">Example</p>
    <p>
      You created an account with Charter on 15th October. Your free trial ended on 15th November,
      so you are billed for your usage between 16th - 30th November on 1st December.
    </p>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <p>
      Charter’s pricing is based on the number of ad channels you have connected and is calculated
      based on usage so you only pay for what you use. Each ad channel you connect is $49/month, but
      if you only use an ad channel for some of a month, you’ll be charged proportionally.
    </p>
  </div>
  <div class="border-l-4 border-[#9f69fb] bg-purple-50 p-4 text-purple-800" role="alert">
    <p class="font-bold">Example</p>
    <p>
      You connected 3 ad channels on 1st December, 2 of them you connected for the whole month and 1
      of them you only connected for a quarter of the month. You’d be charged $49 x 2 + $49 x 0.25 =
      $110.25.
    </p>
  </div>
  <div v-if="page" class="prose prose-invert py-4 pl-5 pr-7">
    <p>
      In the billing and payments section of your account settings, you’ll find estimated charges.
      Charter calculates your usage throughout the month in order to give you an estimate of your
      bill. Please note these are estimates only, the amount you are charged will be finalised at
      the end of the billing period.
    </p>
    <p>Also in this section, you’ll find invoices and receipts for each month you are billed.</p>
  </div>
</template>
