<script setup lang="ts">
import dayjs from 'dayjs'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import ConnectAdChannelInner from '@/ui/components/workspace/ad-channels/connect-ad-channel-options.vue'
import Block from '@/ui/components/workspace/budgets/block.vue'
import BlockGrid from '@/ui/components/workspace/budgets/block-grid.vue'
import TimeframeSelect from '@/ui/components/workspace/budgets/timeframe-select.vue'
import Tabs from '@/ui/components/workspace/tabs.vue'
import { useAccountStore } from '@/stores/account.ts'
import { useWorkspaceStore } from '@/stores/workspace.ts'

const accountStore = useAccountStore()
const workspaceStore = useWorkspaceStore()
const route = useRoute()
// const router = useRouter();

// const loaded = () => {
// if (!workspaceStore.workspace?.ad_channels.length) {
// router.replace({
//   name: "ad-channels",
//   params: {
//     params: route.params.workspace,
//   },
//   query: {
//     setup: "true",
//   },
// });
// return;
// } else if (!workspaceStore.workspace?.storefronts.length) {
//   router.replace({
//     name: "storefronts",
//     params: {
//       params: route.params.workspace,
//     },
//     query: {
//       setup: "true",
//     },
//   });
//   return;
// }
// };
//
// if (workspaceStore.workspace) {
//   loaded();
// } else {
//   watchOnce(() => workspaceStore.workspace, loaded);
// }

const locked = computed<boolean | undefined>(() => {
  if (accountStore.account === undefined) {
    return undefined
  }
  if (accountStore.account.payment_overdue) {
    return true
  }

  return (
    accountStore.account.payment_method === undefined &&
    accountStore.account.price_per_ad_channel > 0 &&
    (accountStore.account.free_trial_ends_at === undefined ||
      accountStore.account.free_trial_ends_at < dayjs.utc().unix() * 1000)
  )
})

const has_ad_channels = computed<boolean | undefined>(() => {
  if (workspaceStore.workspace === undefined) {
    return undefined
  }
  return workspaceStore.workspace.ad_channels.length >= 1
})
</script>
<template>
  <router-view v-if="route.name !== 'budgets'"></router-view>
  <template v-else-if="locked !== undefined && has_ad_channels !== undefined">
    <div class="flex grow flex-col items-center justify-center" v-if="!has_ad_channels">
      <connect-ad-channel-inner class="mt-[-100px]"></connect-ad-channel-inner>
    </div>
    <div v-else>
      <tabs class="mb-7 mt-0">
        <div
          class="relative top-1 flex select-none items-center space-x-12 text-base text-white/30"
        >
          <timeframe-select
            v-if="accountStore.account && !accountStore.account.payment_overdue"
          ></timeframe-select>
        </div>
      </tabs>
      <div class="px-9 opacity-50" v-if="locked">(Hidden whilst your account is overdue)</div>
      <template v-else>
        <block-grid class="mb-10 mt-4" v-if="workspaceStore.workspace">
          <block
            v-for="budget in workspaceStore.workspace.budgets"
            :key="budget.id"
            :budget="budget"
          >
          </block>
        </block-grid>
      </template>
    </div>
    <div class="h-16"></div>
  </template>
</template>
