<script setup lang="ts">
const props = defineProps({
  imagePosition: {
    type: String,
    required: false,
    default: 'left',
  },
  muted: {
    type: String,
    required: false,
    default: undefined,
  },
  title: {
    type: String,
    required: true,
  },
})
</script>
<template>
  <div
    :class="`flex flex-col-reverse space-y-10 space-y-reverse lg:flex-row lg:space-x-16 2xl:space-x-32 ${props.imagePosition !== 'left' ? `lg:flex-row-reverse lg:space-x-reverse 2xl:space-x-reverse` : ``}`"
  >
    <div class="basis-7/12">
      <!--      <img-->
      <!--        class="w-full rounded-sm"-->
      <!--        src="@/assets/placeholder.png"-->
      <!--        alt=""-->
      <!--      />-->
      <slot name="image"></slot>
      <!--      <div-->
      <!--        class="aspect-[4/3] w-full rounded-xl bg-zinc-100 dark:bg-white/3"-->
      <!--      ></div>-->
    </div>
    <div class="flex basis-5/12 flex-col justify-center space-y-4 xl:space-y-5">
      <!--      <span v-if="props.muted" class="text-xl text-zinc-400">{{-->
      <!--        props.muted-->
      <!--      }}</span>-->
      <span
        class="text-balance font-sans text-4xl font-medium leading-relaxed sm:text-5xl sm:leading-tight"
        >{{ props.title }}</span
      >
      <div class="font-mono"><slot name="description"></slot></div>
    </div>
  </div>
</template>
