<script setup lang="ts">
import { Capacitor } from '@capacitor/core'
import { useRoute } from 'vue-router'

import bg from '@/assets/bg.png'
import Features from '@/ui/components/landing/features.vue'
import FooterLinks from '@/ui/components/landing/footer-links.vue'
import Integrations from '@/ui/components/landing/integrations.vue'
import MobileHomeNavigation from '@/ui/components/landing/mobile-home-navigation.vue'
import MobileIntro from '@/ui/components/landing/mobile-intro.vue'
import Navigation from '@/ui/components/landing/navigation.vue'
import Pricing from '@/ui/components/landing/pricing.vue'
// import Pricing from "@/components/legal/pricing.vue";
import Splash from '@/ui/components/landing/splash.vue'
import MobileTopLogo from '@/ui/components/mobile-top-logo.vue'

const route = useRoute()

const VITE_MAINTENANCE = import.meta.env.VITE_MAINTENANCE === 'true'
</script>
<template>
  <!--  linear-gradient(black, black),-->
  <div
    v-if="!VITE_MAINTENANCE"
    class="flex grow flex-col text-xl font-light leading-relaxed"
    :style="`
      background-color: #002835;
      background-image: url(${bg});
      background-size: cover;
      background-position: top;
      background-attachment: fixed;
      background-blend-mode: soft-light;
    `"
  >
    <div class="min-h-screen" style="background-color: rgba(0, 0, 0, 0.82)">
      <template v-if="!Capacitor.isNativePlatform()">
        <navigation></navigation>
        <template v-if="route.name === 'home'">
          <!--          <floating-icons></floating-icons>-->
          <splash></splash>

          <div class="mb-40 flex flex-col space-y-40 pt-24">
            <!--            <div class="mt-12 flex flex-col space-y-24 px-7">-->
            <!--              &lt;!&ndash;              <highlighted-feature></highlighted-feature>&ndash;&gt;-->
            <!--              <steps></steps>-->
            <!--            </div>-->
            <features></features>
            <integrations></integrations>
            <pricing></pricing>
            <!--            <faqs></faqs>-->
            <!--            <demo-cta></demo-cta>-->
          </div>
        </template>
        <template v-else>
          <div class="h-44"></div>
          <div class="container">
            <!--          <router-link class="mb-8 block text-lg" :to="{ name: 'home' }"-->
            <!--            >← Back to main page</router-link-->
            <!--          >-->
            <router-view></router-view>
          </div>
        </template>
        <footer-links></footer-links>
        <div class="h-[106px] sm:hidden"></div>
      </template>
      <template v-else>
        <mobile-top-logo></mobile-top-logo>
        <mobile-intro></mobile-intro>
        <mobile-home-navigation></mobile-home-navigation>
      </template>
    </div>
  </div>
  <div class="flex grow items-center justify-center" v-else>
    Update in progress... we'll be right back.
  </div>
</template>
