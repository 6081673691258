<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="256"
    height="256"
    viewBox="0 0 256 256"
    xml:space="preserve"
  >
    <g
      style="
        stroke: none;
        stroke-width: 0;
        stroke-dasharray: none;
        stroke-linecap: butt;
        stroke-linejoin: miter;
        stroke-miterlimit: 10;
        fill: none;
        fill-rule: nonzero;
        opacity: 1;
      "
      transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
    >
      <linearGradient
        id="SVGID_1"
        gradientUnits="userSpaceOnUse"
        x1="45"
        y1="77.3979"
        x2="45"
        y2="4.7082"
      >
        <stop offset="0%" style="stop-color: rgb(0, 100, 225); stop-opacity: 1" />
        <stop offset="100%" style="stop-color: rgb(0, 130, 251); stop-opacity: 1" />
      </linearGradient>
      <path
        d="M 45 90 L 45 90 C 20.147 90 0 69.853 0 45 v 0 C 0 20.147 20.147 0 45 0 h 0 c 24.853 0 45 20.147 45 45 v 0 C 90 69.853 69.853 90 45 90 z"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: url(#SVGID_1);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform=" matrix(1 0 0 1 0 0) "
        stroke-linecap="round"
      />
      <path
        d="M 69.829 32.38 c -3.21 -4.786 -7.404 -7.337 -11.808 -7.337 c -2.623 0 -5.229 1.17 -7.645 3.277 c -1.635 1.426 -3.143 3.235 -4.552 5.138 c -1.733 -2.194 -3.347 -3.878 -4.909 -5.153 c -2.961 -2.418 -5.797 -3.262 -8.65 -3.262 c -4.926 0 -9.22 3.208 -12.196 7.795 c -3.354 5.172 -5.118 11.87 -5.118 18.297 c 0 3.534 0.698 6.635 2.119 8.999 c 1.741 2.901 4.552 4.826 8.997 4.826 c 3.747 0 6.593 -1.682 9.927 -6.121 c 1.902 -2.535 2.865 -4.071 6.669 -10.815 l 1.893 -3.354 c 0.158 -0.281 0.308 -0.543 0.463 -0.818 c 0.154 0.252 0.305 0.494 0.46 0.753 l 5.39 9.003 c 1.812 3.03 4.167 6.401 6.182 8.299 c 2.621 2.472 4.991 3.053 7.666 3.053 c 4.303 0 6.861 -2.272 8.173 -4.545 c 1.358 -2.351 2.157 -5.327 2.157 -9.377 C 75.048 44.223 73.342 37.621 69.829 32.38 z M 39.397 43.065 c -1.896 2.911 -4.713 7.555 -7.103 10.863 c -2.984 4.127 -4.533 4.548 -6.226 4.548 c -1.312 0 -2.6 -0.591 -3.463 -1.988 c -0.658 -1.066 -1.162 -2.828 -1.162 -5.122 c 0 -5.563 1.578 -11.356 4.155 -15.245 c 1.822 -2.752 4 -4.552 6.564 -4.552 c 3.168 0 5.154 1.981 6.698 3.622 c 0.77 0.818 1.846 2.18 3.09 3.952 L 39.397 43.065 z M 64.718 58.475 c -1.453 0 -2.571 -0.577 -4.167 -2.512 c -1.241 -1.507 -3.363 -4.702 -7.091 -10.913 l -1.544 -2.575 c -1.1 -1.832 -2.143 -3.475 -3.142 -4.958 c 0.176 -0.272 0.353 -0.559 0.527 -0.818 c 2.807 -4.174 5.304 -6.514 8.407 -6.514 c 2.869 0 5.477 1.898 7.49 5.003 c 2.836 4.378 4.123 10.506 4.123 16.027 C 69.322 55.09 68.402 58.475 64.718 58.475 z"
        style="
          stroke: none;
          stroke-width: 1;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: rgb(255, 255, 255);
          fill-rule: nonzero;
          opacity: 1;
        "
        transform=" matrix(1 0 0 1 0 0) "
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>
