<script setup lang="ts">
import { PinInputInput, PinInputRoot } from 'radix-vue'
import type { PropType } from 'vue'
import { onBeforeUnmount, onMounted, ref } from 'vue'

import StyledButton from '@/ui/components/form/styled-button.vue'
import { AuthContext, authContextLabel } from '@/enums/auth-context.ts'
import { ButtonSize } from '@/enums/button-size.ts'
import { ButtonType } from '@/enums/button-type.ts'
import { api } from '@/helpers/api.ts'
import { clientError, userError } from '@/helpers/errors.ts'
import regex from '@/helpers/regex.ts'
import type { AuthenticationRequest } from '@/models/authentication-request.ts'
import type { AuthenticationResult, AuthenticationSuccess } from '@/models/authentication-result.ts'
import { useAuthStore } from '@/stores/auth.ts'

const emit = defineEmits<{
  (e: 'proceed'): void
}>()

const props = defineProps({
  authContext: {
    type: String as PropType<AuthContext>,
    required: true,
  },
  debugEmail: {
    type: String,
    required: false,
    default: undefined,
  },
})
const authStore = useAuthStore()

const pending = ref<boolean>(false)

const authenticationSuccessful = (result: AuthenticationSuccess) => {
  authStore.signIn(result).then(() => {
    emit('proceed')
    pending.value = false
  })
}

// email login

const giveFocus = () => {
  if (stage.value === 0) {
    ;(document.querySelector(`input[name="email"]`) as HTMLElement | undefined)?.focus()
  } else if (stage.value === 1) {
    ;(document.querySelector(`input[name="passcode"]`) as HTMLElement | undefined)?.focus()
  }
}
onMounted(() => {
  window.addEventListener('keydown', keyDownHandler)
})
onBeforeUnmount(() => {
  window.removeEventListener('keydown', keyDownHandler)
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const keyDownHandler = (e: any) => {
  const isPause = e.code === 'Pause'
  const isFKey =
    e.code === 'F1' ||
    e.code === 'F2' ||
    e.code === 'F3' ||
    e.code === 'F4' ||
    e.code === 'F5' ||
    e.code === 'F6' ||
    e.code === 'F7' ||
    e.code === 'F8' ||
    e.code === 'F9' ||
    e.code === 'F10' ||
    e.code === 'F11' ||
    e.code === 'F12'
  const isTab = e.code === 'Tab'
  const isNumLock = e.code === 'NumLock'
  const isScrollLock = e.code === 'ScrollLock'
  const isCapsLock = e.code === 'CapsLock'
  const isPageUp = e.code === 'PageUp'
  const isPageDown = e.code === 'PageDown'
  const isAltGr = e.code === 'AltRight'
  const isContext = e.code === 'ContextMenu'
  const isPrintScreen = e.code === 'PrintScreen'
  const isInsert = e.code === 'Insert'
  const isEnter = e.code === 'Enter'
  const isEscape = e.code === 'Escape'
  const isA = e.code === 'KeyA'
  const isV = e.code === 'KeyV'

  if (
    e.altKey ||
    (e.ctrlKey && !isA && !isV) ||
    isEscape ||
    isCapsLock ||
    isPageUp ||
    isAltGr ||
    isPrintScreen ||
    isPageDown ||
    isTab ||
    isPause ||
    isFKey ||
    isNumLock ||
    isInsert ||
    isContext ||
    isScrollLock
  ) {
    return
  }

  if (e.shiftKey || e.ctrlKey || !isEnter) {
    giveFocus()
  } else if (!e.shiftKey && !e.ctrlKey && isEnter) {
    if (stage.value === AuthStage.EMAIL) {
      submitEmail()
    } else if (stage.value === AuthStage.PASSCODE) {
      submitPasscode()
    }
  }
}

type FormErrors = {
  email?: string
  passcode?: string
}

enum AuthStage {
  EMAIL = 0,
  PASSCODE = 1,
}

const stage = ref<AuthStage>(AuthStage.EMAIL)
const request = ref<AuthenticationRequest | undefined>(undefined)

const email = ref<string>('')
const passcode = ref<string[]>([])

if (props.debugEmail && !email.value.length) {
  email.value = props.debugEmail
}
const errors = ref<FormErrors>({})

const validateEmail = () => {
  if (!email.value.length) {
    errors.value.email = 'Please enter your email address'
  } else if (email.value.trim().length && !regex.email.test(email.value)) {
    errors.value.email = 'Please enter a valid email address'
  } else {
    errors.value.email = undefined
  }
}

const validatePasscode = () => {
  if (passcode.value.length !== 4) {
    errors.value.passcode = 'Please enter the passcode'
  } else {
    errors.value.passcode = undefined
  }
}

const submitPasscode = () => {
  if (pending.value) {
    return
  }
  pending.value = true

  validatePasscode()

  if (errors.value.passcode) {
    pending.value = false
    return
  }
  const passcode_ = passcode.value.join('')

  api
    .put(`auth/email/confirm`, {
      identifier: request.value!.identifier,
      passcode: passcode_,
    })
    .then((data: unknown) => {
      if ((data as AuthenticationResult).success) {
        authenticationSuccessful(data as AuthenticationSuccess)
      } else {
        userError('Passcode incorrect')
      }
      pending.value = false
    })
    .catch(() => {
      pending.value = false
    })
}

const submitEmail = () => {
  if (pending.value) {
    return
  }
  pending.value = true

  validateEmail()

  if (errors.value.email) {
    pending.value = false
    return
  }
  api.get(`auth/email/request?email=${email.value}`).then((data: unknown) => {
    const request_ = data as AuthenticationRequest

    if (!request_.hint) {
      stage.value = AuthStage.PASSCODE
      // toast({
      //   description: `Your passcode has been emailed to ${email.value}`,
      // });
      request.value = request_
      pending.value = false
    } else {
      api
        .put(`auth/email/confirm`, {
          identifier: request_.identifier,
          passcode: request_.hint,
        })
        .then((data: unknown) => {
          if ((data as AuthenticationResult).success) {
            authenticationSuccessful(data as AuthenticationSuccess)
          } else {
            clientError('Please try again')
          }
        })
    }
  })
}
</script>
<template>
  <div>
    <div class="flex flex-col items-center" v-if="stage === AuthStage.EMAIL">
      <span class="w-full text-balance pb-6 text-center font-sans text-lg font-medium"
        >Please enter your email address</span
      >
      <input
        name="email"
        :class="`large text rounded-full text-center ${errors.email ? `invalid` : ``}`"
        v-model="email"
        placeholder="Your email address"
        @input="
          () => {
            if (errors.email) {
              validateEmail()
            }
          }
        "
      />
      <span class="form-error mt-3 text-center" v-if="errors.email">{{ errors.email }}</span>
      <div class="mt-8 flex justify-start">
        <styled-button
          class="rounded-full"
          v-if="stage === AuthStage.EMAIL"
          :type="ButtonType.PRIMARY"
          :size="ButtonSize.MEDIUM"
          @click="submitEmail"
          :submitting="pending"
        >
          {{ authContextLabel(props.authContext) }}
        </styled-button>
      </div>
    </div>
    <div class="flex flex-col" v-if="stage === AuthStage.PASSCODE">
      <span class="w-full pb-6 text-center font-sans text-lg font-medium"
        >Please enter the passcode</span
      >
      <PinInputRoot
        v-model="passcode"
        class="flex items-center justify-center space-x-3"
        @complete="submitPasscode"
      >
        <PinInputInput
          v-for="(id, index) in 4"
          :key="id"
          :index="index"
          class="aspect-square h-[50px] rounded-full text-center"
        />
      </PinInputRoot>
    </div>
    <div :class="`flex items-center justify-center pt-7`">
      <styled-button
        class="rounded-full"
        v-if="stage === AuthStage.PASSCODE"
        :type="ButtonType.PRIMARY"
        :size="ButtonSize.MEDIUM"
        @click="submitPasscode"
        :disabled="passcode.length !== 4"
        :submitting="pending"
      >
        Submit
      </styled-button>
    </div>
  </div>
</template>
