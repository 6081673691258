<script setup lang="ts">
import { computed, nextTick } from 'vue'
import type { PropType } from 'vue'

import InputText from '@/ui/components/form/controls/input-text.vue'
import { CurrencySymbolPosition } from '@/enums/currency-symbol-position.ts'
import type { Currency } from '@/models/currency.ts'
import { useCurrencyStore } from '@/stores/currency.ts'

const emit = defineEmits<{
  (e: 'input', event: KeyboardEvent): void
}>()

const props = defineProps({
  currencyId: {
    type: Object as PropType<number>,
    required: true,
  },
  readonly: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const currencyStore = useCurrencyStore()

const currency = computed<Currency>(() => {
  const currency_ = currencyStore.find(props.currencyId)
  if (!currency_) {
    throw new Error(`Currency with ID ${props.currencyId} not found`)
  }
  return currency_
})

const amount = defineModel<string>()

const formatNumber = (value: string) => {
  const parts = value.split(currency.value.decimal_separator)
  const integerPart = parts[0]
  const decimalPart = parts.length >= 2 ? `${currency.value.decimal_separator}${parts[1]}` : ''
  return (
    integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, currency.value.thousands_separator) + decimalPart
  )
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onInput = (event: any) => {
  const inputElement = event.target
  let value = inputElement.value.replace(new RegExp(currency.value.thousands_separator, 'g'), '') // Remove commas to manipulate raw number

  // Save the initial caret position
  const originalCaretPosition = inputElement.selectionStart

  // Get the number of commas before formatting
  const originalCommasBefore = (
    inputElement.value.match(new RegExp(currency.value.thousands_separator, 'g')) || []
  ).length

  // Handle decimal places
  if (value.includes(currency.value.decimal_separator)) {
    const [integerPart, decimalPart] = value.split(currency.value.decimal_separator)
    if (decimalPart.length > 2) {
      value = `${integerPart}.${decimalPart.slice(0, 2)}`
    }
  }
  // Format the number with commas
  const formattedValue = formatNumber(value)
  amount.value = formattedValue

  // Get the number of commas after formatting
  const newCommasAfter = (
    formattedValue.match(new RegExp(currency.value.thousands_separator, 'g')) || []
  ).length

  // Calculate the caret adjustment based on added/removed commas
  const commaAdjustment = newCommasAfter - originalCommasBefore
  const newCaretPosition = originalCaretPosition + commaAdjustment

  // Restore the caret position
  nextTick(() => {
    if (inputElement) {
      inputElement.value = amount.value
      inputElement.setSelectionRange(newCaretPosition, newCaretPosition)
    }
  })

  emit('input', event)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isNumberKey = (event: any) => {
  const charCode = event.which ? event.which : event.keyCode

  // Allow control keys (backspace, delete, etc.)
  if (charCode < 32) return true

  // Allow digits 0-9
  if (charCode >= 48 && charCode <= 57) return true

  // Allow a single decimal point if it hasn't already been added
  if (amount.value && charCode === 46 && !amount.value.includes(currency.value.decimal_separator)) {
    return true
  }

  // Prevent any other characters
  event.preventDefault()
  return false
}
</script>
<template>
  <div :class="`relative flex w-full items-center`">
    <span
      v-if="currency.symbol_position === CurrencySymbolPosition.LEFT"
      class="absolute left-[14px] text-white/50"
      >{{ currency.symbol }}</span
    >
    <input-text
      :readonly="props.readonly"
      :disabled="props.disabled"
      v-model="amount"
      class="indent-[22px]"
      @input="onInput"
      @keypress="isNumberKey"
    />
    <span
      v-if="currency.symbol_position === CurrencySymbolPosition.RIGHT"
      class="absolute right-[14px] text-white/50"
      >{{ currency.symbol }}</span
    >
  </div>
</template>
